const MenuDashboard = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3125 0C6.86495 0 6.43572 0.182348 6.11926 0.506931C5.80279 0.831513 5.625 1.27174 5.625 1.73077V2.30769H1.6875C1.23995 2.30769 0.810725 2.49004 0.494257 2.81462C0.17779 3.13921 0 3.57943 0 4.03846L0 13.2692C0 13.7283 0.17779 14.1685 0.494257 14.4931C0.810725 14.8177 1.23995 15 1.6875 15H16.3125C16.7601 15 17.1893 14.8177 17.5057 14.4931C17.8222 14.1685 18 13.7283 18 13.2692V4.03846C18 3.57943 17.8222 3.13921 17.5057 2.81462C17.1893 2.49004 16.7601 2.30769 16.3125 2.30769H12.375V1.73077C12.375 1.27174 12.1972 0.831513 11.8807 0.506931C11.5643 0.182348 11.1351 0 10.6875 0H7.3125ZM7.3125 1.15385H10.6875C10.8367 1.15385 10.9798 1.21463 11.0852 1.32282C11.1907 1.43102 11.25 1.57776 11.25 1.73077V2.30769H6.75V1.73077C6.75 1.57776 6.80926 1.43102 6.91475 1.32282C7.02024 1.21463 7.16332 1.15385 7.3125 1.15385ZM9.43425 9.13154L16.875 7.09731V13.2692C16.875 13.4222 16.8157 13.569 16.7102 13.6772C16.6048 13.7854 16.4617 13.8462 16.3125 13.8462H1.6875C1.53832 13.8462 1.39524 13.7854 1.28975 13.6772C1.18426 13.569 1.125 13.4222 1.125 13.2692V7.09615L8.56575 9.13154C8.85029 9.20925 9.14971 9.20925 9.43425 9.13154ZM1.6875 3.46154H16.3125C16.4617 3.46154 16.6048 3.52232 16.7102 3.63052C16.8157 3.73871 16.875 3.88545 16.875 4.03846V5.90308L9.14512 8.01692C9.05004 8.04296 8.94996 8.04296 8.85487 8.01692L1.125 5.90308V4.03846C1.125 3.88545 1.18426 3.73871 1.28975 3.63052C1.39524 3.52232 1.53832 3.46154 1.6875 3.46154Z"
        fill="#6B7B93"
      />
    </svg>
  );
};

export default MenuDashboard;
