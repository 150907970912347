import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const monthNames = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#8B95A5",
      },
    },
    y: {
      grid: {
        color: "#E7EEFA",
        borderDash: [2, 2],
      },
      ticks: {
        color: "#8B95A5",
      },
    },
  },
};

const VisitorChart = ({ type = "daily" }) => {
  const LABELS = {
    daily: Array.from({ length: 30 }, (_, i) => `${i + 1}`),
    monthly: Array.from({ length: 12 }, (_, i) => monthNames[i]),
    yearly: Array.from({ length: 7 }, (_, i) => `${i + 2016}`),
  };
  const DATA = {
    labels: LABELS[type],
    datasets: [
      {
        label: "Kunjungan",
        data: LABELS[type].map(() =>
          faker.datatype.number({ min: 0, max: 100 })
        ),
        backgroundColor: "#3992E6",
        borderRadius: 8,
      },
    ],
  };
  return <Bar data={DATA} options={OPTIONS} />;
};

export default VisitorChart;
