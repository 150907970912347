import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const FileInput = ({ ...rest }) => {
  // Register Filepond plugins
  registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform
  );

  return (
    <FilePond
      {...rest}
      maxFileSize="2MB"
      className="file-uploader file-uploader-grid"
      acceptedFileTypes={["image/png", "image/jpeg", "image/webp"]}
      labelIdle={`
        <div>
          <div class="btn btn-primary rounded-pill mb-3">
            <i class="bi bi-cloud-arrow-up me-1"></i>Unggah Foto
          </div>
          <div>atau seret ke sini</div>
        </div>
      `}
    />
  );
};

export default FileInput;
