export const INPUT_TYPE = {
  INPUT: "input",
  TEXT: "text",
  TEXTAREA: "textarea",
  SELECT: "select",
  PASSWORD: "password",
  CHECKBOX: "checkbox",
  SWITCH: "switch",
  PRICE: "price",
  SELECT_SYNC: "select_sync",
  SELECT_CREATABLE: "select_creatable",
  UPLOAD_IMAGE: "upload_image",
  TEXT_EDITOR: "text_editor",
  RADIO: "radio",
  COLOR: "color",
  YEAR: "year",
  DATE: "date",
  IMAGE: "image",
  PERCENT: "percent",
  PREFIX_SUFFIX: "prefix_suffix",
  UPLOAD_IMAGES_GRID: "upload_images_grid",
  UPLOAD_DOC: "upload_doc",
  UPLOAD_PDF: "upload_pdf",
  HOURS: "hours",
  TIME: "time",
  QUILL: "quill",
  NUMBER: "number",
  PHONE: "phone",
  NIK: "nik",
  REKENING: "rekening",
  NIP: "nip",
};

export const REACT_SELECT_CUSTOM_STYLES = {
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? "#00BFA6" : "#e7eaf0"}`,
    boxShadow: state.isFocused ? "0 0 0 3px rgba(0, 225, 115, 0.3)" : "none",
    "&:hover": {
      border: "1px solid #00BFA6",
    },
    height: "48px",
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: "0 0 4px 4px",
    zIndex: "10",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#8898a9",
    marginLeft: ".75rem",
  }),
  input: (styles) => ({
    ...styles,
    marginLeft: ".75rem",
    padding: "0",
    boxShadow: "none",
  }),
  valueContainer: (styles) => ({
    ...styles,
    height: "40px",
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    // backgroundColor: isSelected ? "#019543" : isFocused ? "#019543" : undefined,
    // color: isFocused ? "#fff" : isSelected ? "#fff" : undefined,
    backgroundColor: isSelected ? "#FFFFFF" : isFocused ? "#00BFA6" : undefined,
    color: isSelected ? "#00BFA6" : isFocused ? "#FFFFFF" : undefined,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    marginTop: "4px",
    marginBottom: "0",
    // height: "30px",
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "48px",
  }),
};

export const REACT_SELECT_CUSTOM_STYLES_FN = ({ isMulti, control }) => ({
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? "#00BFA6" : "#e7eaf0"}`,
    boxShadow: state.isFocused ? "0 0 0 3px rgba(1, 149, 68, 0.3)" : "none",
    "&:hover": {
      border: "1px solid #00BFA6",
    },
    height: isMulti ? "max-content" : "48px",
    ...control,
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: "0 0 4px 4px",
    zIndex: "10",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#8898a9",
    marginLeft: ".75rem",
  }),
  input: (styles) => ({
    ...styles,
    marginLeft: ".75rem",
    padding: "0",
    boxShadow: "none",
  }),
  valueContainer: (styles) => ({
    ...styles,
    height: isMulti ? "max-content" : "40px",
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    // backgroundColor: isSelected ? "#019543" : isFocused ? "#019543" : undefined,
    // color: isFocused ? "#fff" : isSelected ? "#fff" : undefined,
    backgroundColor: isSelected ? "#FFFFFF" : isFocused ? "#00BFA6" : undefined,
    color: isSelected ? "#00BFA6" : isFocused ? "#FFFFFF" : undefined,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    marginTop: "4px",
    marginBottom: "0",
    height: "30px",
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "48px",
  }),
});

export const STATUS_CODE = {
  200: 200,
  400: 400,
  409: 409,
  404: 404,
  422: 422,
  500: 500,
};

export const STATUS_PERNIKAHAN_OPTIONS = [
  {
    label: "Menikah",
    value: "menikah",
  },
  {
    label: "Belum Menikah",
    value: "belum menikah",
  },
  {
    label: "Cerai Hidup",
    value: "cerai hidup",
  },
  {
    label: "Cerai Mati",
    value: "cerai mati",
  },
];
