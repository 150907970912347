import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Login from "pages/login";
import ForgotPassword from "pages/auth/forgot-password";
import ResetPassword from "pages/auth/reset-password";
import Dashboard from "pages/dashboard";
import MenuDashboard from "icons/menu/MenuDashboard";
import MenuMaster from "icons/menu/MenuMaster";
import MenuPasien from "icons/menu/MenuPasien";
import MenuPelayanan from "icons/menu/MenuPelayanan";
import MenuRawatInap from "icons/menu/MenuRawatInap";
import MenuKeuangan from "icons/menu/MenuKeuangan";
import PrivateRoute from "./private";

const MenuPengaturan = lazy(() => import("icons/menu/MenuPengaturan"));
const DaftarPelayanan = lazy(() => import("pages/data-master/pelayanan"));
const DaftarSubsubJP = lazy(() =>
  import("pages/data-master/pelayanan/subsub-jp")
);
const DaftarSubJP = lazy(() => import("pages/data-master/pelayanan/sub-jp"));
const DaftarJP = lazy(() =>
  import("pages/data-master/pelayanan/jenis-pelayanan")
);
const DaftarTarif = lazy(() => import("pages/data-master/tarif"));
const EditTarif = lazy(() => import("pages/data-master/tarif/edit"));
const DaftarPenjamin = lazy(() => import("pages/data-master/penjamin"));
const DaftarTransportasi = lazy(() =>
  import("pages/data-master/sarana-transportasi")
);
const DaftarKamar = lazy(() => import("pages/data-master/kamar"));
const DaftarPasien = lazy(() => import("pages/data-pasien"));
const CetakTiket = lazy(() => import("pages/pasien/cetak-tiket"));
const TiketAntrean = lazy(() => import("pages/pasien/tiket-antrean"));
const DaftarPelayananPasien = lazy(() => import("pages/pelayanan"));
const TambahPelayananPasien = lazy(() => import("pages/pelayanan/create"));
const PeriksaPasien = lazy(() => import("pages/pelayanan/periksa"));
const DashboardPoliGigi = lazy(() => import("pages/poli-gigi/dashboard"));
const DaftarPelayananPasienPoliGigi = lazy(() =>
  import("pages/poli-gigi/pelayanan")
);
const PeriksaPasienPoliGigi = lazy(() =>
  import("pages/poli-gigi/pelayanan/periksa")
);
const Keuangan = lazy(() => import("pages/keuangan"));
const DetailKeuangan = lazy(() => import("pages/keuangan/detail"));
const CreateJenisPelayanan = lazy(() =>
  import("pages/data-master/pelayanan/jenis-pelayanan/create")
);
const CreateSubJenisPelayanan = lazy(() =>
  import("pages/data-master/pelayanan/sub-jp/create")
);
const CreateSubSubJenisPelayanan = lazy(() =>
  import("pages/data-master/pelayanan/subsub-jp/create")
);
const CreatePenjamin = lazy(() => import("pages/data-master/penjamin/create"));
const CreateSaranaTransportasi = lazy(() =>
  import("pages/data-master/sarana-transportasi/create")
);
const CreateKamar = lazy(() => import("pages/data-master/kamar/create"));
const AkunRekening = lazy(() => import("pages/admin-keuangan/akun-rekening"));
const RekeningTahun = lazy(() => import("pages/admin-keuangan/rekening-tahun"));
const PengaturanAkunRekening = lazy(() =>
  import("pages/admin-keuangan/pengaturan-akun-rekening")
);
const PostingJurnal = lazy(() => import("pages/admin-keuangan/posting-jurnal"));
const JurnalHarian = lazy(() => import("pages/admin-keuangan/jurnal-harian"));
const BukuBesar = lazy(() => import("pages/admin-keuangan/buku-besar"));
const CashFlow = lazy(() => import("pages/admin-keuangan/cash-flow"));
const PengaturanKeuangan = lazy(() =>
  import("pages/admin-keuangan/pengaturan-keuangan")
);
const CreatePasien = lazy(() => import("pages/data-pasien/create"));
const Logout = lazy(() => import("pages/auth/logout"));

const MenuPuskesmas = lazy(() => import("icons/menu/MenuPuskesmas"));
const PuskesmasList = lazy(() => import("pages/puskesmas"));
const CreatePuskesmas = lazy(() => import("pages/puskesmas/create"));
const EditPuskesmas = lazy(() => import("pages/puskesmas/edit"));
const DaftarPengguna = lazy(() => import("pages/pengguna"));
const TambahPengguna = lazy(() => import("pages/pengguna/create"));
const DaftarPoliklinik = lazy(() => import("pages/data-master/poliklinik"));
const CreatePoliklinik = lazy(() =>
  import("pages/data-master/poliklinik/create")
);
const EditPoliklinik = lazy(() => import("pages/data-master/poliklinik/edit"));
const EditJenisPelayanan = lazy(() =>
  import("pages/data-master/pelayanan/jenis-pelayanan/edit")
);
const EditSubJenisPelayanan = lazy(() =>
  import("pages/data-master/pelayanan/sub-jp/edit")
);
const EditSubSubJenisPelayanan = lazy(() =>
  import("pages/data-master/pelayanan/subsub-jp/edit")
);
const EditPenjamin = lazy(() => import("pages/data-master/penjamin/edit"));
const EditSaranaTransportasi = lazy(() =>
  import("pages/data-master/sarana-transportasi/edit")
);
const CreateKategoriPenyakit = lazy(() =>
  import("pages/data-master/penyakit/kategori/create")
);
const DaftarKategoriPenyakit = lazy(() =>
  import("pages/data-master/penyakit/kategori")
);
const EditKategoriPenyakit = lazy(() =>
  import("pages/data-master/penyakit/kategori/edit")
);
const CreatePenyakit = lazy(() =>
  import("pages/data-master/penyakit/penyakit/create")
);
const DaftarPenyakit = lazy(() =>
  import("pages/data-master/penyakit/penyakit")
);
const EditPenyakit = lazy(() =>
  import("pages/data-master/penyakit/penyakit/edit")
);
const EditPasien = lazy(() => import("pages/data-pasien/edit"));
const EditKamar = lazy(() => import("pages/data-master/kamar/edit"));
const EditPelayanan = lazy(() => import("pages/pelayanan/edit"));
const CreatePegawai = lazy(() => import("pages/data-pegawai/create"));
const MenuPegawai = lazy(() => import("icons/menu/MenuPegawai"));
const DaftarPegawai = lazy(() => import("pages/data-pegawai"));
const EditPegawai = lazy(() => import("pages/data-pegawai/edit"));
const CreateKategoriPegawai = lazy(() =>
  import("pages/data-master/kategori-pegawai/create")
);
const DaftarKategoriPegawai = lazy(() =>
  import("pages/data-master/kategori-pegawai")
);
const EditJenisTenaga = lazy(() =>
  import("pages/data-master/kategori-pegawai/edit")
);
const DaftarJabatan = lazy(() =>
  import("pages/data-master/kategori-pegawai/jabatan")
);
const CreateJabatan = lazy(() =>
  import("pages/data-master/kategori-pegawai/jabatan/create")
);
const EditJabatan = lazy(() =>
  import("pages/data-master/kategori-pegawai/jabatan/edit")
);
const KeluhanPasien = lazy(() => import("pages/pelayanan/keluhan"));
const BahanHabisPakai = lazy(() =>
  import("pages/data-master/bahan-habis-pakai")
);
const CreateBahanHabisPakai = lazy(() =>
  import("pages/data-master/bahan-habis-pakai/create")
);
const EditBahanHabisPakai = lazy(() =>
  import("pages/data-master/bahan-habis-pakai/edit")
);
const PerlengkapanLainnya = lazy(() =>
  import("pages/data-master/perlengkapan-lainnya")
);
const CreatePerlengkapanLainnya = lazy(() =>
  import("pages/data-master/perlengkapan-lainnya/create")
);
const EditPerlengkapanLainnya = lazy(() =>
  import("pages/data-master/perlengkapan-lainnya/edit")
);
const DaftarPenunjangMedis = lazy(() =>
  import("pages/data-master/penunjang-medis")
);
const CreatePenunjangMedis = lazy(() =>
  import("pages/data-master/penunjang-medis/create")
);
const EditPenunjangMedis = lazy(() =>
  import("pages/data-master/penunjang-medis/edit")
);
const DaftarLayananPM = lazy(() =>
  import("pages/data-master/penunjang-medis/layanan")
);
const CreateLayananPM = lazy(() =>
  import("pages/data-master/penunjang-medis/layanan/create")
);
const EditLayananPM = lazy(() =>
  import("pages/data-master/penunjang-medis/layanan/edit")
);
const IconObat = lazy(() => import("icons/obat/IconObat"));
const DaftarKategoriObat = lazy(() => import("pages/obat/kategori"));
const CreateKategoriObat = lazy(() => import("pages/obat/kategori/create"));
const EditKategoriObat = lazy(() => import("pages/obat/kategori/edit"));
const ObatDaftarObat = lazy(() => import("pages/obat/obat"));
const ObatCreateObat = lazy(() => import("pages/obat/obat/create"));
const ObatEditObat = lazy(() => import("pages/obat/obat/edit"));
const DaftarUnitObat = lazy(() => import("pages/obat/unit"));
const CreateUnitObat = lazy(() => import("pages/obat/unit/create"));
const EditUnitObat = lazy(() => import("pages/obat/unit/edit"));
const DaftarFormulaObat = lazy(() => import("pages/obat/formula"));
const CreateFormulaObat = lazy(() => import("pages/obat/formula/create"));
const EditFormulaObat = lazy(() => import("pages/obat/formula/edit"));
const DaftarMasterObat = lazy(() => import("pages/obat/master"));
const CreateMasterObat = lazy(() => import("pages/obat/master/create"));
const EditMasterObat = lazy(() => import("pages/obat/master/edit"));
const DaftarPaketObat = lazy(() => import("pages/obat/paket"));
const CreatePaketObat = lazy(() => import("pages/obat/paket/create"));
const EditPaketObat = lazy(() => import("pages/obat/paket/edit"));
const DaftarSatuanObat = lazy(() => import("pages/obat/satuan"));
const CreateSatuanObat = lazy(() => import("pages/obat/satuan/create"));
const EditSatuanObat = lazy(() => import("pages/obat/satuan/edit"));
const PelayananPeriksa = lazy(() => import("pages/pelayananperiksa"));

const DaftarKodeAkun = lazy(() => import("pages/akun/kode-akun"));
const DaftarNeracaPerbandingan = lazy(() =>
  import("pages/akun/neraca-perbandingan")
);
const JurnalUmum = lazy(() => import("pages/akun/jurnal/jurnal-umum"));
const CreateJurnalUmum = lazy(() =>
  import("pages/akun/jurnal/create-jurnal-umum")
);
const BukuKasUmum = lazy(() => import("pages/akun/jurnal/buku-kas-umum"));
const DaftarHistoriTransaksiPO = lazy(() =>
  import("pages/akun/buku-pembantu/persediaan-obat/history-transaksi-po")
);
const DetailHistoriTransaksiPO = lazy(() =>
  import("pages/akun/buku-pembantu/persediaan-obat/detail-histori-transaksi-po")
);
const CreateTransaksiPO = lazy(() =>
  import("pages/akun/buku-pembantu/persediaan-obat/create-transaksi-po")
);
const DaftarSaldoPO = lazy(() =>
  import("pages/akun/buku-pembantu/persediaan-obat/saldo-po")
);
const DetailSaldoPO = lazy(() =>
  import("pages/akun/buku-pembantu/persediaan-obat/detail-saldo-po")
);
const DaftarHistoriTransaksiPBHP = lazy(() =>
  import(
    "pages/akun/buku-pembantu/persedian-bahan-habis-pakai/history-transaksi-pbhp"
  )
);
const DetailHistoriTransaksiPBHP = lazy(() =>
  import(
    "pages/akun/buku-pembantu/persedian-bahan-habis-pakai/detail-history-transaksi-pbhp"
  )
);
const CreateTransaksiPBHP = lazy(() =>
  import(
    "pages/akun/buku-pembantu/persedian-bahan-habis-pakai/create-transaksi-pbhp"
  )
);
const DaftarSaldoPBHP = lazy(() =>
  import("pages/akun/buku-pembantu/persedian-bahan-habis-pakai/saldo-pbhp")
);
const DaftarHistoriTransaksiPL = lazy(() =>
  import("pages/akun/buku-pembantu/persediaan-lainnya/history-transaksi-pl")
);
const CreateTransaksiPL = lazy(() =>
  import("pages/akun/buku-pembantu/persediaan-lainnya/create-transaksi-pl")
);
const DaftarSaldoPL = lazy(() =>
  import("pages/akun/buku-pembantu/persediaan-lainnya/saldo-pl")
);
const DetailHistoriTransaksiPL = lazy(() =>
  import(
    "pages/akun/buku-pembantu/persediaan-lainnya/detail-history-transaksi-pl"
  )
);
const DaftarNeraca = lazy(() => import("pages/akun/laporan-keuangan/neraca"));
const DaftarLaporanOperasional = lazy(() =>
  import("pages/akun/laporan-keuangan/laporan-operasional")
);
const LaporanPerubahanEkuitas = lazy(() =>
  import("pages/akun/laporan-keuangan/laporan-perubahan-ekuitas")
);
const ArusKas = lazy(() => import("pages/akun/laporan-keuangan/arus-kas"));

const DaftarAkunAset = lazy(() => import("pages/akun/aset"));
const DetailAset = lazy(() => import("pages/akun/aset/detail"));
const CreateAset = lazy(() => import("pages/akun/aset/create"));
const EditAset = lazy(() => import("pages/akun/aset/edit"));
const DaftarBukuBesar = lazy(() => import("pages/akun/buku-besar"));
const NeracaLajur = lazy(() => import("pages/akun/neraca-lajur"));

const MenuManajemenAkses = lazy(() => import("icons/menu/MenuManajemenAkses"));
const CreateAkses = lazy(() => import("pages/manajemen-akses/create"));
const DaftarAkses = lazy(() => import("pages/manajemen-akses"));
const EditRole = lazy(() => import("pages/manajemen-akses/edit"));
const UbahPassword = lazy(() => import("pages/ubah-password"));
const MenuPengguna = lazy(() => import("icons/menu/MenuPengguna"));
const PelayananRawatInap = lazy(() => import("pages/pelayanan-rawat-inap"));
const PelayananRawatInapDetail = lazy(() =>
  import("pages/pelayanan-rawat-inap/detail")
);
const PelayananKasir = lazy(() => import("pages/pelayanan-kasir"));
const PelayananApoteker = lazy(() =>
  import("pages/pelayanan-apoteker/DetailMedisPasien")
);
const PelayananPenunjangMedis = lazy(() =>
  import("pages/pelayanan-penunjang-medis")
);

export const routes = [
  {
    exact: true,
    path: "*",
    element: <Navigate to="/dashboard" />,
  },
  {
    exact: true,
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    exact: true,
    path: "/login",
    element: <Login />,
  },
  {
    exact: true,
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    exact: true,
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    exact: true,
    path: "/logout",
    element: <Logout />,
  },
  // #region // Dashboard
  {
    exact: true,
    name: "Dashboard",
    icon: <MenuDashboard />,
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  // #endregion
  // #region // Puskesmas On Menu
  {
    exact: true,
    name: "Puskesmas",
    path: "/puskesmas",
    permission: "access-puskesmas",
    icon: <MenuPuskesmas />,
    children: [
      {
        exact: true,
        name: "Daftar",
        path: "/puskesmas/daftar",
        permission: "view-puskesmas",
        element: (
          <PrivateRoute>
            <PuskesmasList />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Tambah",
        path: "/puskesmas/tambah",
        permission: "create-puskesmas",
        element: (
          <PrivateRoute>
            <CreatePuskesmas />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/puskesmas/:id/edit",
        permission: "edit-puskesmas",
        element: (
          <PrivateRoute>
            <EditPuskesmas />
          </PrivateRoute>
        ),
      },
    ],
  },
  // #endregion
  // #region // Data Pengguna
  {
    exact: true,
    name: "Pengguna",
    permission: "access-user",
    icon: <MenuPengguna />,
    children: [
      {
        exact: true,
        name: "Daftar",
        path: "/data-pengguna/daftar",
        permission: "view-user",
        element: (
          <PrivateRoute>
            <DaftarPengguna />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Tambah",
        path: "/data-pengguna/tambah",
        permission: "create-user",
        element: (
          <PrivateRoute>
            <TambahPengguna />
          </PrivateRoute>
        ),
      },
    ],
  },
  // #endregion
  
  // #region // Data Master
  {
    exact: true,
    name: "Data Master",
    icon: <MenuMaster />,
    children: [
      // #region // Obat
      {
        exact: true,
        name: "Obat",
        icon: <IconObat />,
        children: [
          // #region // Obat - Paket Obat
          {
            exact: true,
            name: "Paket Obat",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/obat/paket-obat/list",
                element: (
                  <PrivateRoute>
                    <DaftarPaketObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/obat/paket-obat/add",
                element: (
                  <PrivateRoute>
                    <CreatePaketObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/obat/paket-obat/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditPaketObat />
                  </PrivateRoute>
                ),
              },
            ],
          },
          // #endregion
          // #region // Obat - Master Obat
          {
            exact: true,
            name: "Master Obat",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/obat/master-obat/list",
                element: (
                  <PrivateRoute>
                    <DaftarMasterObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/obat/master-obat/add",
                element: (
                  <PrivateRoute>
                    <CreateMasterObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/obat/master-obat/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditMasterObat />
                  </PrivateRoute>
                ),
              },
            ],
          },
          // #endregion
          // #region // Obat - Kategori Obat
          {
            exact: true,
            name: "Kategori Obat",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/obat/kategori-obat/list",
                element: (
                  <PrivateRoute>
                    <DaftarKategoriObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/obat/kategori-obat/add",
                element: (
                  <PrivateRoute>
                    <CreateKategoriObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/obat/kategori-obat/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditKategoriObat />
                  </PrivateRoute>
                ),
              },
            ],
          },
          // #endregion
          // #region // Obat - Daftar Obat
          {
            exact: true,
            name: "Daftar Obat",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/obat/obat/list",
                element: (
                  <PrivateRoute>
                    <ObatDaftarObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/obat/obat/add",
                element: (
                  <PrivateRoute>
                    <ObatCreateObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/obat/obat/:id/edit",
                element: (
                  <PrivateRoute>
                    <ObatEditObat />
                  </PrivateRoute>
                ),
              },
            ],
          },
          // #endregion
          // #region // Obat - Formula Obat
          {
            exact: true,
            name: "Formula Obat",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/obat/formula-obat/list",
                element: (
                  <PrivateRoute>
                    <DaftarFormulaObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/obat/formula-obat/add",
                element: (
                  <PrivateRoute>
                    <CreateFormulaObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/obat/formula-obat/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditFormulaObat />
                  </PrivateRoute>
                ),
              },
            ],
          },
          // #endregion
          // #region // Obat - Satuan Obat
          {
            exact: true,
            name: "Satuan Obat",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/obat/satuan/list",
                element: (
                  <PrivateRoute>
                    <DaftarSatuanObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/obat/satuan/add",
                element: (
                  <PrivateRoute>
                    <CreateSatuanObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/obat/satuan/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditSatuanObat />
                  </PrivateRoute>
                ),
              },
            ],
          },
          // #endregion
          // #region // Obat - Jenis Obat
          {
            exact: true,
            name: "Jenis Obat",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/obat/jenis-obat/list",
                element: (
                  <PrivateRoute>
                    <DaftarUnitObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/obat/jenis-obat/add",
                element: (
                  <PrivateRoute>
                    <CreateUnitObat />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/obat/jenis-obat/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditUnitObat />
                  </PrivateRoute>
                ),
              },
            ],
          },
          // #endregion
        ],
      },
      // #endregion
      // #region // Data Master - Poliklinik
      {
        exact: true,
        name: "Poliklinik",
        permission: "access-poliklinik",
        children: [
          {
            exact: true,
            name: "Daftar",
            path: "/data-master/poliklinik/daftar",
            permission: "access-poliklinik",
            element: (
              <PrivateRoute>
                <DaftarPoliklinik />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah",
            path: "/data-master/poliklinik/tambah",
            permission: "create-poliklinik",
            element: (
              <PrivateRoute>
                <CreatePoliklinik />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/poliklinik/:id/edit",
            permission: "edit-poliklinik",
            element: (
              <PrivateRoute>
                <EditPoliklinik />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Data Master - Pelayanan
      {
        exact: true,
        name: "Pelayanan",
        path: "/data-master/pelayanan",
        helper: "jenis-pelayanan",
        children: [
          {
            exact: true,
            name: "Jenis Pelayanan",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/data-master/pelayanan/jenis-pelayanan/list",
                element: (
                  <PrivateRoute>
                    <DaftarJP />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/data-master/pelayanan/jenis-pelayanan/add",
                element: (
                  <PrivateRoute>
                    <CreateJenisPelayanan />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/data-master/pelayanan/jenis-pelayanan/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditJenisPelayanan />
                  </PrivateRoute>
                ),
              },
            ],
          },
          {
            exact: true,
            name: "Sub JP",
            path: "/data-master/pelayanan/sub-jenis-pelayanan",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/data-master/pelayanan/sub-jenis-pelayanan/daftar",
                element: (
                  <PrivateRoute>
                    <DaftarSubJP />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/data-master/pelayanan/sub-jenis-pelayanan/add",
                element: (
                  <PrivateRoute>
                    <CreateSubJenisPelayanan />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/data-master/pelayanan/sub-jenis-pelayanan/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditSubJenisPelayanan />
                  </PrivateRoute>
                ),
              },
            ],
          },
          {
            exact: true,
            name: "Subsub JP",
            path: "/data-master/pelayanan/sub-sub-jp",
            children: [
              {
                exact: true,
                name: "Daftar",
                path: "/data-master/pelayanan/sub-sub-jp/list",
                element: (
                  <PrivateRoute>
                    <DaftarSubsubJP />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Tambah",
                path: "/data-master/pelayanan/sub-sub-jp/add",
                element: (
                  <PrivateRoute>
                    <CreateSubSubJenisPelayanan />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/data-master/pelayanan/sub-sub-jp/:id/edit",
                element: (
                  <PrivateRoute>
                    <EditSubSubJenisPelayanan />
                  </PrivateRoute>
                ),
              },
            ],
          },
          // {
          //   exact: true,
          //   name: "Daftar",
          //   path: "/data-master/pelayanan",
          //   element: (
          //     <PrivateRoute>
          //       <DaftarPelayanan />
          //     </PrivateRoute>
          //   ),
          // },
          // {
          //   exact: true,
          //   name: "Tambah",
          //   path: "/data-master/pelayanan/add",
          //   element: (
          //     <PrivateRoute>
          //       <CreatePelayanan />
          //     </PrivateRoute>
          //   ),
          // },
        ],
      },
      // #endregion
      // #region // Data Master - Tarif
      {
        exact: true,
        name: "Tarif",
        path: "/data-master/tarif",
        element: (
          <PrivateRoute>
            <DaftarTarif />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/data-master/tarif/:id/edit",
        element: (
          <PrivateRoute>
            <EditTarif />
          </PrivateRoute>
        ),
      },
      // #endregion
      // #region // Data Master - Penyakit
      {
        exact: true,
        name: "Penyakit",
        children: [
          {
            exact: true,
            path: "/data-master/penyakit/kategori/create",
            element: (
              <PrivateRoute>
                <CreateKategoriPenyakit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penyakit/kategori/:id/edit",
            element: (
              <PrivateRoute>
                <EditKategoriPenyakit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penyakit/kategori",
            name: "Kategori",
            element: (
              <PrivateRoute>
                <DaftarKategoriPenyakit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penyakit",
            name: "Penyakit",
            element: (
              <PrivateRoute>
                <DaftarPenyakit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penyakit/create",
            element: (
              <PrivateRoute>
                <CreatePenyakit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penyakit/:id/edit",
            element: (
              <PrivateRoute>
                <EditPenyakit />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Data Master - Obat
      // #endregion
      // #region // Data Master - Penjamin
      {
        exact: true,
        name: "Penjamin",
        path: "/data-master/penjamin",
        permission: "access-penjamin",
        children: [
          {
            exact: true,
            name: "Daftar",
            path: "/data-master/penjamin/list",
            permission: "access-penjamin",
            element: (
              <PrivateRoute>
                <DaftarPenjamin />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penjamin/:id/edit",
            permission: "edit-penjamin",
            element: (
              <PrivateRoute>
                <EditPenjamin />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah",
            path: "/data-master/penjamin/add",
            permission: "create-penjamin",
            element: (
              <PrivateRoute>
                <CreatePenjamin />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Data Master - Sarana Transportasi
      {
        exact: true,
        name: "Sarana Transportasi",
        path: "/data-master/sarana-transportasi",
        permission: "access-sarana-transportasi",
        children: [
          {
            exact: true,
            name: "Daftar",
            path: "/data-master/sarana-transportasi/list",
            permission: "access-sarana-transportasi",
            element: (
              <PrivateRoute>
                <DaftarTransportasi />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah",
            path: "/data-master/sarana-transportasi/add",
            permission: "create-sarana-transportasi",
            element: (
              <PrivateRoute>
                <CreateSaranaTransportasi />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/sarana-transportasi/:id/edit",
            permission: "edit-sarana-transportasi",
            element: (
              <PrivateRoute>
                <EditSaranaTransportasi />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Data Master - Kamar
      {
        exact: true,
        name: "Kamar",
        path: "/data-master/kamar",
        permission: "access-kamar",
        children: [
          {
            exact: true,
            name: "Daftar",
            path: "/data-master/kamar/list",
            permission: "access-kamar",
            element: (
              <PrivateRoute>
                <DaftarKamar />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah",
            path: "/data-master/kamar/add",
            permission: "create-kamar",
            element: (
              <PrivateRoute>
                <CreateKamar />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/kamar/:id/edit",
            permission: "edit-kamar",
            element: (
              <PrivateRoute>
                <EditKamar />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Data Master - Jenis Tenaga
      {
        exact: true,
        name: "Jenis Tenaga",
        path: "/data-master/jenis-tenaga",
        permission: "access-jenis-tenaga",
        children: [
          {
            exact: true,
            name: "Daftar",
            path: "/data-master/jenis-tenaga/list",
            permission: "access-jenis-tenaga",
            element: (
              <PrivateRoute>
                <DaftarKategoriPegawai />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah",
            path: "/data-master/jenis-tenaga/add",
            permission: "create-jenis-tenaga",
            element: (
              <PrivateRoute>
                <CreateKategoriPegawai />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/jenis-tenaga/:id/edit",
            permission: "edit-jenis-tenaga",
            element: (
              <PrivateRoute>
                <EditJenisTenaga />
              </PrivateRoute>
            ),
          },
          // List Jabatan
          {
            exact: true,
            path: "/data-master/jenis-tenaga/:id/jabatan",
            permission: "edit-jenis-tenaga",
            element: (
              <PrivateRoute>
                <DaftarJabatan />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/jenis-tenaga/:id/jabatan/add",
            permissin: "edit-jenis-tenaga",
            element: (
              <PrivateRoute>
                <CreateJabatan />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/jenis-tenaga/:id/jabatan/:idJabatan/edit",
            element: (
              <PrivateRoute>
                <EditJabatan />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Data Master - Penunjang Medis
      {
        exact: true,
        name: "Penunjang Medis",
        path: "/data-master/penunjang-medis",
        permission: "access-penunjang-medis",
        children: [
          {
            exact: true,
            name: "Daftar",
            path: "/data-master/penunjang-medis/list",
            permission: "access-penunjang-medis",
            element: (
              <PrivateRoute>
                <DaftarPenunjangMedis />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah",
            path: "/data-master/penunjang-medis/add",
            permission: "create-penunjang-medis",
            element: (
              <PrivateRoute>
                <CreatePenunjangMedis />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penunjang-medis/:id/edit",
            permission: "edit-penunjang-medis",
            element: (
              <PrivateRoute>
                <EditPenunjangMedis />
              </PrivateRoute>
            ),
          },
          // Layanan penunjang medis
          {
            exact: true,
            path: "/data-master/penunjang-medis/:id/layanan",
            permission: "view-penunjang-medis",
            element: (
              <PrivateRoute>
                <DaftarLayananPM />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penunjang-medis/:id/layanan/add",
            permission: "edit-penunjang-medis",
            element: (
              <PrivateRoute>
                <CreateLayananPM />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/penunjang-medis/:id/layanan/:idLayanan/edit",
            permission: "edit-penunjang-medis",
            element: (
              <PrivateRoute>
                <EditLayananPM />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Data Master - Bahan Habis Pakai
      {
        exact: true,
        name: "Bahan Habis Pakai",
        path: "/data-master/bahan-habis-pakai",
        permission: "access-bahan-habis-pakai",
        children: [
          {
            exact: true,
            name: "Daftar",
            path: "/data-master/bahan-habis-pakai/list",
            permission: "access-bahan-habis-pakai",
            element: (
              <PrivateRoute>
                <BahanHabisPakai />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah",
            path: "/data-master/bahan-habis-pakai/add",
            permission: "create-bahan-habis-pakai",
            element: (
              <PrivateRoute>
                <CreateBahanHabisPakai />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/bahan-habis-pakai/:id/edit",
            permission: "edit-bahan-habis-pakai",
            element: (
              <PrivateRoute>
                <EditBahanHabisPakai />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Data Master - Perlengkapan Lainnya
      {
        exact: true,
        name: "Perlengkapan Lainnya",
        path: "/data-master/perlengkapan-lainnya",
        permission: "access-perlengkapan-lainnya",
        children: [
          {
            exact: true,
            name: "Daftar",
            path: "/data-master/perlengkapan-lainnya/list",
            permission: "access-perlengkapan-lainnya",
            element: (
              <PrivateRoute>
                <PerlengkapanLainnya />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Tambah",
            path: "/data-master/perlengkapan-lainnya/add",
            permission: "create-perlengkapan-lainnya",
            element: (
              <PrivateRoute>
                <CreatePerlengkapanLainnya />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/data-master/perlengkapan-lainnya/:id/edit",
            permission: "edit-perlengkapan-lainnya",
            element: (
              <PrivateRoute>
                <EditPerlengkapanLainnya />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
    ],
  },
  // #endregion
  // #region // Pegawai
  {
    exact: true,
    name: "Data Pegawai",
    permission: "access-pegawai",
    icon: <MenuPegawai />,
    children: [
      {
        exact: true,
        name: "Daftar",
        path: "/pegawai/list",
        permission: "access-pegawai",
        element: (
          <PrivateRoute>
            <DaftarPegawai />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Tambah",
        path: "/pegawai/add",
        permission: "create-pegawai",
        element: (
          <PrivateRoute>
            <CreatePegawai />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/pegawai/:id/edit",
        permission: "edit-pegawai",
        element: (
          <PrivateRoute>
            <EditPegawai />
          </PrivateRoute>
        ),
      },
    ],
  },
  // #endregion
  // #region // Data Pasien
  {
    exact: true,
    name: "Data Pasien",
    permission: "access-pasien",
    icon: <MenuPasien />,
    children: [
      {
        exact: true,
        name: "Daftar",
        path: "/pasien/list",
        permission: "view-pasien",
        element: (
          <PrivateRoute>
            <DaftarPasien />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Tambah",
        path: "/pasien/add",
        permission: "create-pasien",
        element: (
          <PrivateRoute>
            <CreatePasien />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/pasien/:id/edit",
        permission: "edit-pasien",
        element: (
          <PrivateRoute>
            <EditPasien />
          </PrivateRoute>
        ),
      },
    ],
  },
  // #endregion

  // #region // Pelayanan
  {
    exact: true,
    name: "Pelayanan",
    icon: <MenuPelayanan />,
    permission: "access-pelayanan",
    children: [
      {
        exact: true,
        name: "Daftar",
        path: "/pelayanan/list",
        permission: "access-pelayanan",
        element: (
          <PrivateRoute>
            <DaftarPelayananPasien />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Tambah",
        path: "/pelayanan/add",
        permission: "create-pelayanan",
        element: (
          <PrivateRoute>
            <TambahPelayananPasien />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/pelayanan/:id/edit",
        permission: "edit-pelayanan",
        element: (
          <PrivateRoute>
            <EditPelayanan />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Cetak Tiket",
        path: "/pasien/cetak-tiket",
        element: <CetakTiket />,
      },
      {
        exact: true,
        name: "Tiket Antrean",
        path: "/pasien/tiket-antrean",
        element: <TiketAntrean />,
      },
      {
        // exact: true,
        // name: "Periksa",
        path: "/pelayanan/periksa/:id_periksa",
        permission: "periksa",
        element: (
          <PrivateRoute>
            <PelayananPeriksa />
          </PrivateRoute>
        ),
      },
      {
        path: "/pelayanan/periksa-penunjang-medis/:id_penunjang",
        permission: "edit-penunjang-medis-pelayanan", // saat ini
        element: (
          <PrivateRoute>
            <PelayananPenunjangMedis />
          </PrivateRoute>
        ),
      },
      {
        path: "/pelayanan/detail-pembayaran/:id_periksa",
        // permission: "bayar",
        element: (
          <PrivateRoute>
            <PelayananKasir />
          </PrivateRoute>
        ),
      },
      {
        path: "/pelayanan/detail-medis-pasien/:id_periksa",
        permission: "ambil-obat",
        element: (
          <PrivateRoute>
            <PelayananApoteker />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/pelayanan/keluhan",
        permission: "keluhan",
        element: (
          <PrivateRoute>
            <KeluhanPasien />
          </PrivateRoute>
        ),
      },
    ],
  },
  // #endregion

  // #region // Pelayanan Rawat Inap
  {
    name: "Rawat Inap",
    icon: <MenuRawatInap />,
    path: "/pelayanan-rawat-inap",
    permission: "access-rawat-inap",
    element: (
      <PrivateRoute>
        <PelayananRawatInap />
      </PrivateRoute>
    ),
  },
  {
    path: "/pelayanan-rawat-inap/detail/:id_periksa",
    permission: "periksa-rawat-inap",
    element: (
      <PrivateRoute>
        <PelayananRawatInapDetail />
      </PrivateRoute>
    ),
  },
  // #endregion

  // #region // Keuangan
  {
    exact: true,
    name: "Keuangan",
    icon: <MenuKeuangan />,
    // permission: "access-keuangan",
    children: [
      {
        exact: true,
        name: "Kode Akun",
        path: "/akun/kode-akun/list",
        element: (
          <PrivateRoute>
            <DaftarKodeAkun />
          </PrivateRoute>
        ),
      },
      // #region // Keuangan - Jurnal
      {
        exact: true,
        name: "Jurnal",
        children: [
          {
            exact: true,
            name: "Jurnal Umum",
            path: "/akun/jurnal-umum/list",
            element: (
              <PrivateRoute>
                <JurnalUmum />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/akun/jurnal-umum/add",
            element: (
              <PrivateRoute>
                <CreateJurnalUmum />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Buku Kas Umum",
            path: "/akun/buku-kas-umum",
            element: (
              <PrivateRoute>
                <BukuKasUmum />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #endregion
      // #region // Keuangan - Buku Pembantu
      {
        exact: true,
        name: "Buku Pembantu",
        children: [
          {
            exact: true,
            name: "Persediaan Obat",
            children: [
              {
                exact: true,
                name: "Histori Transaksi",
                path: "/akun/persediaan-obat/histori-transaksi",
                element: (
                  <PrivateRoute>
                    <DaftarHistoriTransaksiPO />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/akun/persediaan-obat/histori-transaksi/:idx",
                element: (
                  <PrivateRoute>
                    <DetailHistoriTransaksiPO />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/akun/persediaan-obat/transaksi/add",
                element: (
                  <PrivateRoute>
                    <CreateTransaksiPO />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Saldo",
                path: "/akun/persediaan-obat/saldo",
                element: (
                  <PrivateRoute>
                    <DaftarSaldoPO />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/akun/persediaan-obat/saldo/:idx",
                element: (
                  <PrivateRoute>
                    <DetailSaldoPO />
                  </PrivateRoute>
                ),
              },
            ],
          },
          {
            exact: true,
            name: "Persediaan BHP",
            children: [
              {
                exact: true,
                name: "Histori Transaksi",
                path: "/akun/persediaan-bahan-habis-pakai/histori-transaksi",
                element: (
                  <PrivateRoute>
                    <DaftarHistoriTransaksiPBHP />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/akun/persediaan-bahan-habis-pakai/histori-transaksi/:idx",
                element: (
                  <PrivateRoute>
                    <DetailHistoriTransaksiPBHP />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/akun/persediaan-bahan-habis-pakai/transaksi/add",
                element: (
                  <PrivateRoute>
                    <CreateTransaksiPBHP />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Saldo",
                path: "/akun/persediaan-bahan-habis-pakai/saldo",
                element: (
                  <PrivateRoute>
                    <DaftarSaldoPBHP />
                  </PrivateRoute>
                ),
              },
            ],
          },
          {
            exact: true,
            name: "Persediaan Lainnya",
            children: [
              {
                exact: true,
                name: "Histori Transaksi",
                path: "/akun/persediaan-lainnya/histori-transaksi",
                element: (
                  <PrivateRoute>
                    <DaftarHistoriTransaksiPL />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/akun/persediaan-lainnya/histori-transaksi/:idx",
                element: (
                  <PrivateRoute>
                    <DetailHistoriTransaksiPL />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                path: "/akun/persediaan-lainnya/transaksi/add",
                element: (
                  <PrivateRoute>
                    <CreateTransaksiPL />
                  </PrivateRoute>
                ),
              },
              {
                exact: true,
                name: "Saldo",
                path: "/akun/persediaan-lainnya/saldo",
                element: (
                  <PrivateRoute>
                    <DaftarSaldoPL />
                  </PrivateRoute>
                ),
              },
            ],
          },
        ],
      },
      // //#endregion
      // #region // Keuangan - Laporan Keuangan
      {
        exact: true,
        name: "Laporan Keuangan",
        children: [
          {
            exact: true,
            name: "Neraca",
            path: "/akun/laporan-keuangan/neraca",
            element: (
              <PrivateRoute>
                <DaftarNeraca />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Laporan Operasional",
            path: "/akun/laporan-keuangan/laporan-operasional",
            element: (
              <PrivateRoute>
                <DaftarLaporanOperasional />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Laporan Perubahan Ekuitas",
            path: "/akun/laporan-keuangan/laporan-perubahan-ekuitas",
            element: (
              <PrivateRoute>
                <LaporanPerubahanEkuitas />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            name: "Arus Kas",
            path: "/akun/laporan-keuangan/arus-kas",
            element: (
              <PrivateRoute>
                <ArusKas />
              </PrivateRoute>
            ),
          },
        ],
      },
      // #region  // Aset
      {
        exact: true,
        name: "Pencatatan Aset",
        path: "/akun/pencatatan-aset/list",
        element: (
          <PrivateRoute>
            <DaftarAkunAset />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/akun/pencatatan-aset/:id_coa_code/detail",
        element: (
          <PrivateRoute>
            <DetailAset />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/akun/pencatatan-aset/add",
        element: (
          <PrivateRoute>
            <CreateAset />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/akun/pencatatan-aset/:id_pencatatan_aset/edit",
        element: (
          <PrivateRoute>
            <EditAset />
          </PrivateRoute>
        ),
      },
      // #endregion
      // #region  // Buku Besar
      {
        exact: true,
        name: "Buku Besar",
        path: "/akun/buku-besar",
        element: (
          <PrivateRoute>
            <DaftarBukuBesar />
          </PrivateRoute>
        ),
      },
      // #endregion
      // #region  // Neraca Lajur
      {
        exact: true,
        name: "Neraca Lajur",
        path: "/akun/neraca-lajur",
        element: (
          <PrivateRoute>
            <NeracaLajur />
          </PrivateRoute>
        ),
      },
      // #endregion
      // #region // Neraca Perbandingan
      {
        exact: true,
        name: "Neraca Perbandingan",
        path: "/akun/neraca-perbandingan",
        element: (
          <PrivateRoute>
            <DaftarNeracaPerbandingan />
          </PrivateRoute>
        ),
      },
    ],
  },
  // #endregion
  // #region // Unknown
  {
    exact: true,
    path: "/keuangan/detail",
    element: (
      <PrivateRoute>
        <DetailKeuangan />
      </PrivateRoute>
    ),
  },
  // #endregion

  // #region // Manajemen Akses
  {
    exact: true,
    name: "Manajemen Akses",
    icon: <MenuManajemenAkses />,
    children: [
      {
        exact: true,
        name: "Daftar",
        path: "/manajemen-akses/list",
        element: (
          <PrivateRoute>
            <DaftarAkses />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/manajemen-akses/:id/edit",
        element: (
          <PrivateRoute>
            <EditRole />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Tambah",
        path: "/manajemen-akses/add",
        element: (
          <PrivateRoute>
            <CreateAkses />
          </PrivateRoute>
        ),
      },
    ],
  },
  // #endregion

  // #region // Sisi Pasien
  // #endregion

  //#region // Poli Gigi
  {
    exact: true,
    path: "/poli-gigi/dashboard",
    icon: <MenuDashboard />,
    poli: "poli-gigi",
    name: "Dashboard",
    element: (
      <PrivateRoute>
        <DashboardPoliGigi />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    name: "Pelayanan",
    icon: <MenuPelayanan />,
    poli: "poli-gigi",
    path: "/poli-gigi/pelayanan/list",
    element: (
      <PrivateRoute>
        <DaftarPelayananPasienPoliGigi />
      </PrivateRoute>
    ),
    // {
    //   exact: true,
    //   name: "Tambah",
    //   path: "/pelayanan/add",
    //   element: (
    //     <PrivateRoute>
    //       <TambahPelayananPasien />
    //     </PrivateRoute>
    //   ),
    // },
    // {
    //   exact: true,
    //   path: "/pelayanan/periksa",
    //   element: (
    //     <PrivateRoute>
    //       <PeriksaPasien />
    //     </PrivateRoute>
    //   ),
    // },
  },
  {
    exact: true,
    path: "/poli-gigi/pelayanan/periksa",
    element: (
      <PrivateRoute>
        <PeriksaPasienPoliGigi />
      </PrivateRoute>
    ),
  },
  // #region // Keuangan
  {
    exact: true,
    name: "Keuangan",
    icon: <MenuKeuangan />,
    poli: "poli-gigi",
  },
  // #endregion
  // #region // Pengaturan
  {
    exact: true,
    name: "Pengaturan",
    icon: <MenuPengaturan />,
    poli: "poli-gigi",
  },
  // #endregion

  // #region // Poli Umum
  {
    exact: true,
    path: "/poli-umum/dashboard",
    icon: <MenuDashboard />,
    poli: "poli-umum",
    name: "Dashboard",
    element: (
      <PrivateRoute>
        <DashboardPoliGigi />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    name: "Pelayanan",
    icon: <MenuPelayanan />,
    poli: "poli-umum",
    path: "/poli-umum/pelayanan/list",
    element: (
      <PrivateRoute>
        <DaftarPelayananPasienPoliGigi />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/poli-umum/pelayanan/periksa",
    poli: "poli-umum",
    element: (
      <PrivateRoute>
        <PeriksaPasienPoliGigi />
      </PrivateRoute>
    ),
  },
  // #region // Keuangan
  {
    exact: true,
    name: "Keuangan",
    icon: <MenuKeuangan />,
    poli: "poli-umum",
  },
  // #endregion
  // #region // Pengaturan
  {
    exact: true,
    name: "Pengaturan",
    icon: <MenuPengaturan />,
    poli: "poli-umum",
  },
  // #endregion

  // #region // Apotek
  {
    exact: true,
    path: "/apotek/dashboard",
    icon: <MenuDashboard />,
    poli: "apotek",
    name: "Dashboard",
    element: (
      <PrivateRoute>
        <DashboardPoliGigi />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    name: "Pelayanan",
    icon: <MenuPelayanan />,
    poli: "apotek",
    path: "/apotek/pelayanan/list",
    element: (
      <PrivateRoute>
        <Keuangan />
      </PrivateRoute>
    ),
  },
  // #region // Keuangan
  {
    exact: true,
    name: "Keuangan",
    icon: <MenuKeuangan />,
    poli: "apotek",
  },
  // #endregion
  // #region // Pengaturan
  {
    exact: true,
    name: "Pengaturan",
    icon: <MenuPengaturan />,
    poli: "apotek",
  },
  // #endregion

  // #region // Keuangan
  {
    exact: true,
    path: "/keuangan/dashboard",
    icon: <MenuDashboard />,
    poli: "keuangan",
    name: "Dashboard",
    element: (
      <PrivateRoute>
        <DashboardPoliGigi />
      </PrivateRoute>
    ),
  },
  // #region // Keuangan
  {
    exact: true,
    name: "Keuangan",
    path: "/keuangan",
    poli: "keuangan",
    icon: <MenuKeuangan />,
    children: [
      {
        exact: true,
        name: "Akun Rekening",
        path: "/keuangan/akun-rekening",
        poli: "keuangan",
        element: (
          <PrivateRoute>
            <AkunRekening />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Rekening Tahunan",
        path: "/keuangan/rekening-tahunan",
        poli: "keuangan",
        element: (
          <PrivateRoute>
            <RekeningTahun />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Pengaturan Rekening",
        path: "/keuangan/pengaturan-rekening",
        poli: "keuangan",
        element: (
          <PrivateRoute>
            <PengaturanAkunRekening />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Posting Jurnal",
        path: "/keuangan/posting-jurnal",
        poli: "keuangan",
        element: (
          <PrivateRoute>
            <PostingJurnal />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Jurnal Harian",
        path: "/keuangan/jurnal-harian",
        poli: "keuangan",
        element: (
          <PrivateRoute>
            <JurnalHarian />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Buku Besar",
        path: "/keuangan/buku-besar",
        poli: "keuangan",
        element: (
          <PrivateRoute>
            <BukuBesar />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Cash Flow",
        path: "/keuangan/cash-flow",
        poli: "keuangan",
        element: (
          <PrivateRoute>
            <CashFlow />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Neraca Keuangan",
        path: "/keuangan/neraca-keuangan",
        poli: "keuangan",
        element: <PrivateRoute></PrivateRoute>,
      },
      {
        exact: true,
        name: "Pengaturan Keuangan",
        path: "/keuangan/pengaturan-keuangan",
        poli: "keuangan",
        element: (
          <PrivateRoute>
            <PengaturanKeuangan />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        name: "Remunerasi",
        path: "/keuangan/remunerasi",
        poli: "keuangan",
        element: <PrivateRoute></PrivateRoute>,
      },
    ],
  },
  // #region // Keuangan
  {
    exact: true,
    name: "Rekap Keuangan",
    icon: <MenuKeuangan />,
    path: "/keuangan/rekap-keuangan",
    poli: "keuangan",
    element: (
      <PrivateRoute>
        <Keuangan />
      </PrivateRoute>
    ),
  },
  // #endregion
  // #region // Pengaturan
  {
    exact: true,
    name: "Pengaturan",
    icon: <MenuPengaturan />,
    poli: "keuangan",
  },
  // #endregion
  // #region // Ubah Password
  {
    exact: true,
    name: "Ubah Password",
    // icon: <MenuKeuangan />,
    path: "/ubah-password/dashboard",
    poli: "ubah-password",
    element: (
      <PrivateRoute>
        <UbahPassword />
      </PrivateRoute>
    ),
  },
  // #endregion
];

export default routes;
