const MenuPasien = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_46_69)">
        <path
          d="M14.25 2.25H9.354C9.23801 2.25077 9.12336 2.22512 9.01875 2.175L6.65175 0.987C6.33929 0.831409 5.99505 0.75029 5.646 0.75H3.75C2.7558 0.751191 1.80267 1.14666 1.09966 1.84966C0.396661 2.55267 0.00119089 3.5058 0 4.5L0 13.5C0.00119089 14.4942 0.396661 15.4473 1.09966 16.1503C1.80267 16.8533 2.7558 17.2488 3.75 17.25H14.25C15.2442 17.2488 16.1973 16.8533 16.9003 16.1503C17.6033 15.4473 17.9988 14.4942 18 13.5V6C17.9988 5.0058 17.6033 4.05267 16.9003 3.34966C16.1973 2.64666 15.2442 2.25119 14.25 2.25ZM3.75 2.25H5.646C5.76199 2.24923 5.87664 2.27488 5.98125 2.325L8.34825 3.50925C8.66039 3.66614 9.00465 3.74855 9.354 3.75H14.25C14.6985 3.75073 15.1366 3.8855 15.508 4.13701C15.8794 4.38852 16.1671 4.74528 16.3342 5.1615L1.5 5.2455V4.5C1.5 3.90326 1.73705 3.33097 2.15901 2.90901C2.58097 2.48705 3.15326 2.25 3.75 2.25ZM14.25 15.75H3.75C3.15326 15.75 2.58097 15.5129 2.15901 15.091C1.73705 14.669 1.5 14.0967 1.5 13.5V6.7455L16.5 6.66075V13.5C16.5 14.0967 16.2629 14.669 15.841 15.091C15.419 15.5129 14.8467 15.75 14.25 15.75Z"
          fill="#6B7B93"
        />
      </g>
      <defs>
        <clipPath id="clip0_46_69">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuPasien;
