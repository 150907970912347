import { useSelector } from "react-redux";
import {
  NavLink,
  useResolvedPath,
  useMatch,
  useLocation,
} from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import Popover from "@idui/react-popover";
import { findActiveMenuInRoutes, replaceAll } from "utils/helper";

const NavItemGrandChild = ({ name, path }) => {
  let resolvedChild = useResolvedPath(path ?? "#");
  let matchChild = useMatch({ path: resolvedChild.pathname });

  if (name) {
    return (
      <li className="nav-item ms-5">
        <NavLink
          to={`${path}?page=1&per_page=10&q=` ?? "#"}
          className={`${matchChild ? "nav-link text-primary" : "nav-link"}`}
        >
          {name}
        </NavLink>
      </li>
    );
  }

  return null;
};

const NavItemChildInChild = ({ name, path, children }) => {
  let resolvedChild = useResolvedPath(path ?? "#");
  let matchChild = useMatch({ path: resolvedChild.pathname });
  const matchSubJp = useMatch("/data-master/pelayanan/*");

  const { pathname } = useLocation();
  const { miniMenu } = useSelector((state) => state.app);

  const isActiveMenu = findActiveMenuInRoutes(
    matchChild || matchSubJp,
    path,
    children,
    pathname
  );

  if (name) {
    if (miniMenu) {
      return (
        <li className="nav-item">
          {children?.length > 0 ? (
            <Popover
              trigger="click"
              placement="right"
              content={
                <ul className="nav nav-sm flex-column">
                  {children?.map((child, idx) => (
                    <NavItemChildInChild {...child} key={idx} />
                  ))}
                </ul>
              }
            >
              <span type="button" className="nav-link">
                {name} <i className="bi ms-2 bi-chevron-right" />
              </span>
            </Popover>
          ) : (
            <NavLink
              role="button"
              to={`${path}?page=1&per_page=10&q=` ?? "#"}
              className={`${matchChild ? "nav-link text-primary" : "nav-link"}`}
            >
              {name}
            </NavLink>
          )}

          <div
            id={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
            className={isActiveMenu ? "collapse show" : "collapse"}
          >
            <ul className="nav nav-sm flex-column">
              {children?.map((child, idx) => (
                <NavItemGrandChild {...child} key={idx} />
              ))}
            </ul>
          </div>
        </li>
      );
    }

    return (
      <li className="nav-item ms-5">
        {children?.length > 0 ? (
          <span
            type="button"
            data-bs-toggle="collapse"
            aria-expanded={isActiveMenu ? "true" : "false"}
            data-bs-target={`#${replaceAll(name?.toLowerCase(), " ", "-")}`}
            aria-controls={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
            className="nav-link"
          >
            {name}
          </span>
        ) : (
          <NavLink
            role="button"
            to={`${path}?page=1&per_page=10&q=` ?? "#"}
            className={`${matchChild ? "nav-link text-primary" : "nav-link"}`}
          >
            {name}
          </NavLink>
        )}

        <div
          id={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
          className={isActiveMenu ? "collapse show" : "collapse"}
        >
          <ul className="nav nav-sm flex-column">
            {children?.map((child, idx) => (
              <NavItemGrandChild {...child} key={idx} />
            ))}
          </ul>
        </div>
      </li>
    );
  }

  return null;
};

const NavItemChild = ({ name, path, childs }) => {
  let resolvedChild = useResolvedPath(path ?? "#");
  let matchChild = useMatch({ path: `${resolvedChild.pathname}/*` });
  const { pathname } = useLocation();

  const { miniMenu } = useSelector((state) => state.app);

  const isActiveMenu = findActiveMenuInRoutes(
    matchChild,
    path,
    childs,
    pathname
  );

  if (name) {
    if (miniMenu) {
      return (
        <li className="nav-item">
          {childs?.length > 0 ? (
            <Popover
              trigger="click"
              placement="right"
              content={
                <ul className="nav nav-sm flex-column">
                  {childs?.map((child, idx) => (
                    <NavItemChildInChild {...child} key={idx} />
                  ))}
                </ul>
              }
            >
              <span type="button" className="nav-link">
                {name} <i className="bi ms-2 bi-chevron-right" />
              </span>
            </Popover>
          ) : (
            <NavLink
              role="button"
              to={`${path}?page=1&per_page=10&q=` ?? "#"}
              className={`${matchChild ? "nav-link text-primary" : "nav-link"}`}
            >
              {name}
            </NavLink>
          )}

          <div
            id={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
            className={isActiveMenu ? "collapse show" : "collapse"}
          >
            <ul className="nav nav-sm flex-column">
              {childs?.map((child, idx) => (
                <NavItemChildInChild {...child} key={idx} />
              ))}
            </ul>
          </div>
        </li>
      );
    }

    return (
      <li className="nav-item">
        {childs?.length > 0 ? (
          <span
            type="button"
            data-bs-toggle="collapse"
            aria-expanded={isActiveMenu ? "true" : "false"}
            data-bs-target={`#${replaceAll(name?.toLowerCase(), " ", "-")}`}
            aria-controls={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
            className="nav-link"
          >
            {name}
          </span>
        ) : (
          <NavLink
            role="button"
            to={`${path}?page=1&per_page=10&q=` ?? "#"}
            className={`${matchChild ? "nav-link text-primary" : "nav-link"}`}
          >
            {name}
          </NavLink>
        )}

        <div
          id={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
          className={isActiveMenu ? "collapse show" : "collapse"}
        >
          <ul className="nav nav-sm flex-column">
            {childs?.map((child, idx) => (
              <NavItemChildInChild {...child} key={idx} />
            ))}
          </ul>
        </div>
      </li>
    );
  }

  return null;
};

const NavItem = ({ title, name, icon, badge, path, childs, userType }) => {
  let resolved = useResolvedPath(path ?? "#");
  let match = useMatch({ path: resolved.pathname });

  const { pathname } = useLocation();
  // const { user } = useSelector((state) => state.auth);
  const { miniMenu } = useSelector((state) => state.app);

  const isActiveMenu =
    findActiveMenuInRoutes(match, path, childs, pathname) ||
    // check menu array with same path with currentpath
    childs.some((child) =>
      child?.children?.some((el) => el.path === match.pathname)
    );

  // const filterMenuByUserType =
  //   userType?.length > 0 ? userType.includes(user?.type) : false;

  // if (filterMenuByUserType) {
  if (miniMenu) {
    return (
      <>
        <li className="nav-item">
          {childs?.length > 0 ? (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{name}</Tooltip>}
            >
              <Popover
                trigger="click"
                placement="right"
                content={
                  <ul className="nav nav-sm flex-column">
                    {childs?.map((child, idx) => (
                      <NavItemChild
                        {...child}
                        childs={child.children}
                        key={idx}
                      />
                    ))}
                  </ul>
                }
              >
                <span type="button" className="nav-link">
                  {icon}
                  <i className="bi bi-chevron-right" />
                </span>
              </Popover>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{name}</Tooltip>}
            >
              <NavLink
                to={`${path}?page=1&per_page=10&q=` ?? "#"}
                role="button"
                className={`${
                  isActiveMenu ? "nav-link bg-primary text-white" : "nav-link"
                }`}
              >
                <i
                  className={`${icon} ${isActiveMenu ? "text-primary" : ""}`}
                ></i>{" "}
                {badge && (
                  <span className="badge badge-sm bg-soft-success text-success rounded-pill ms-auto">
                    {badge}
                  </span>
                )}
              </NavLink>
            </OverlayTrigger>
          )}
          <div
            id={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
            className={isActiveMenu ? "collapse show" : "collapse"}
          >
            <ul className="nav nav-sm flex-column">
              {childs?.map((child, idx) => (
                <NavItemChild {...child} childs={child.children} key={idx} />
              ))}
            </ul>
          </div>
        </li>
      </>
    );
  }

  return (
    <>
      {title && (
        <>
          <hr className="navbar-divider my-4 opacity-70" />
          <li>
            <span className="nav-link text-xs font-semibold text-uppercase text-muted ls-wide">
              {title}
            </span>
          </li>
        </>
      )}
      <li className="nav-item">
        {childs?.length > 0 ? (
          <span
            type="button"
            data-bs-toggle="collapse"
            style={{ color: "#303030", fontWeight: 400 }}
            aria-expanded={isActiveMenu ? "true" : "false"}
            data-bs-target={`#${replaceAll(name?.toLowerCase(), " ", "-")}`}
            aria-controls={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
            className={
              isActiveMenu
                ? "bg-primary breksi-nav-icon text-white nav-link"
                : "nav-link"
            }
          >
            {icon}&nbsp;{name}
          </span>
        ) : (
          <NavLink
            to={`${path}?page=1&per_page=10&q=` ?? "#"}
            role="button"
            className={`${
              isActiveMenu ? "nav-link bg-primary text-white" : "nav-link"
            }`}
          >
            <div
              children={icon}
              className={isActiveMenu ? "breksi-nav-icon" : ""}
            />
            &nbsp;
            {/* <i className={`${icon} ${isActiveMenu ? "text-primary" : ""}`}></i>{" "} */}
            <span
              style={{
                color: isActiveMenu ? "#fff" : "rgb(48, 48,48)",
                fontWeight: "400",
              }}
            >
              {name}
            </span>
            {badge && (
              <span className="badge badge-sm bg-soft-success text-success rounded-pill ms-auto">
                {badge}
              </span>
            )}
          </NavLink>
        )}
        <div
          id={`${replaceAll(name?.toLowerCase(), " ", "-")}`}
          className={isActiveMenu ? "collapse show" : "collapse"}
        >
          <ul className="nav nav-sm flex-column">
            {childs?.map((child, idx) => (
              <NavItemChild {...child} childs={child.children} key={idx} />
            ))}
          </ul>
        </div>
      </li>
    </>
  );
  // }

  // return <></>;
};

NavItem.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  badge: PropTypes.string,
  path: PropTypes.string,
  children: PropTypes.arrayOf({
    name: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string,
  }),
};

export default NavItem;
