import { Navigate, useNavigate } from "react-router-dom";
import { isUserAuthenticated } from "utils/auth";
import LockImage from "assets/forgot-password/lock.png";
import { Form } from "react-bootstrap";
import FormInput from "components/Input";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { request } from "utils/request";
import { toast } from "react-hot-toast";

const ForgotPassword = () => {
  const isLoggin = isUserAuthenticated();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const submitRequest = (values) => {
    setLoading(true);
    request
      .post("/api/password/forgot-password", {
        email: values.email,
        callback_url:
          !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? "https://dev-simpus.sandboxindonesia.id/reset-password"
            : "https://simpus.sandboxindonesia.id/reset-password",
      })
      .then((res) => {
        toast.success(res.data.message);
        navigate("/login");
      })
      .finally(() => setLoading(false));
  };

  if (isLoggin) {
    return <Navigate to="/" />;
  }
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="max-w-[600px] md:shadow-[8px_14px_38px_rgba(0,_0,_0,_0.1)] rounded-[14px] p-3 md:p-[106px_85px] flex items-center justify-center w-full md:w-[70%] lg:w-[50%] h-[600px]">
        <div>
          <img src={LockImage} alt="lock" className="mx-auto" />
          <h1 className="text-[28px] font-bold mt-5 mx-auto text-center">
            Lupa Password
          </h1>
          {/* <div className="h-[4px] w-[60px] bg-black mx-auto mt-2 rounded-[7px]" /> */}
          <Form onSubmit={handleSubmit(submitRequest)}>
            <div className="rounded-input-container mt-10">
              <FormInput
                type={"email"}
                name="email"
                label={"Email"}
                required={true}
                placeholder={"Masukkan email"}
                register={register}
                control={control}
                errors={errors}
                errorMessage={"Email tidak boleh kosong"}
                inputStyles={{
                  width: "100%",
                  maxWidth: "431px",
                  borderRadius: "7px",
                }}
              />
            </div>
            <div className="w-full mt-[56px]">
              <button
                className="text-[16px] font-medium text-white bg-[#00BFA6] p-4 rounded-[7px] w-full cursor-pointer h-[50px]"
                type="submit"
                disabled={!isValid || loading}
              >
                {loading ? "Loading..." : "Kirimkan saya tautan"}
              </button>
            </div>
          </Form>
          <div className="w-full mt-[30px]">
            <div className=" mt-3 text-center">
              <Link
                className="text-base text-[#00BFA6] hover:[#007B6B]"
                to="/login"
              >
                kembali ke halaman masuk
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
