import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PerfectScrollbar from "react-perfect-scrollbar";
import NavItem from "./NavItem";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import SimpusLogo from "assets/logo/simpus.png";

const Sidebar = () => {
  const { menu: menuData, miniMenu } = useSelector((state) => state.app);
  // const { user } = useSelector((state) => state.auth);
  const user = true;
  const { pathname } = useLocation();

  const menu = useMemo(() => {
    const isPoliGigi = pathname.includes("poli-gigi");
    const isPoliUmum = pathname.includes("poli-umum");
    const isApotek = pathname.includes("apotek");
    const isAdministrasiKeuangan = pathname.includes("/keuangan");
    if (isPoliGigi)
      return menuData.filter((item) => {
        return item.poli && item.poli === "poli-gigi";
      });
    if (isPoliUmum)
      return menuData.filter((item) => {
        return item.poli && item.poli === "poli-umum";
      });
    if (isApotek)
      return menuData.filter((item) => {
        return item.poli && item.poli === "apotek";
      });
    if (isAdministrasiKeuangan)
      return menuData.filter((item) => {
        return item.poli && item.poli === "keuangan";
      });
    return menuData.filter((item) => {
      return !item.poli;
    });
  }, [menuData, pathname]);

  return (
    <nav
      // id="sidebar"
      style={{ width: "100%" }}
      className="navbar-custom-lg navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg fixed lg:top-[70px]"
    >
      <PerfectScrollbar className="container-fluid">
        <button
          className="navbar-toggler ms-n2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarCollapse"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {user ? (
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            <ul className="navbar-nav">
              <div
                style={{
                  paddingLeft: "20px",
                }}
                className="lg:hidden"
              >
                <Link to="/" className="d-flex align-items-center gap-5">
                  <img alt="simpus" src={SimpusLogo} className="logo-left" />
                </Link>
              </div>
              {menu
                .filter((navItem) => navItem.name)
                .map((navItem) => (
                  <NavItem
                    key={uuidv4()}
                    {...navItem}
                    childs={navItem?.children ?? []}
                  />
                ))}
            </ul>

            {/* <div className="mt-10">
              <button
                className="btn btn-neutral d-block mx-auto"
                onClick={() => dispatch(setMiniMenu(!miniMenu))}
              >
                <i className={`bi bi-chevron-${miniMenu ? "right" : "left"}`} />
              </button>
            </div> */}
          </div>
        ) : (
          <div style={{ paddingTop: "20px" }}>
            <Spinner className="d-block mx-auto" animation="border" />
          </div>
        )}
      </PerfectScrollbar>
    </nav>
  );
};

export default Sidebar;
