const StatItem = ({ title = "Title", number = 999, icon = null }) => {
  return (
    <div className="bg-white border border-[#ECEFF3] rounded-[16px] p-[22px] flex items-start justify-between">
      <div>
        <div className="text-[16px] font-medium text-[#6B7B93] mb-[18px]">
          {title}
        </div>
        <div className="text-[28px] font-medium text-[#16192C]">{number}</div>
      </div>
      <div>{icon}</div>
    </div>
  );
};

export default StatItem;
