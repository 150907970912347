import { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  FormText,
  InputGroup,
  FormCheck,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import SelectCreatable from "react-select/creatable";
import AsyncSelectCreatable from "react-select/async-creatable";
import {
  INPUT_TYPE,
  REACT_SELECT_CUSTOM_STYLES,
  REACT_SELECT_CUSTOM_STYLES_FN,
} from "utils/constant";
import MDIInput from "./mdi-input";
import PriceInput from "./price-input";
import TextEditor from "./text-editor";
import DatePicker from "./date/date-picker";
import MaskInput from "./mask-input";
import PhoneInput from "./phone-input";
import PrefixSuffixInput from "./prefix-suffix-input";
import MultipleImages from "./multiple-images";
import TimePicker from "react-time-picker";
import ReactQuill from "react-quill";
import NumberInput from "./number-input";
import ExtInput from "./ext-input";
import UploadDoc from "./upload-doc";
import UploadPdf from "./pdf-input";

const FormInput = ({
  label,
  type,
  align,
  name,
  errorMessage,
  register,
  errors,
  control,
  required,
  placeholder,
  inputType,
  as,
  mb,
  key,
  height,
  isMulti,
  creatable,
  disabled,
  options,
  defaultValue,
  maxLength,
  minLength,
  hidden,
  setValueAs,
  boldLabel,
  isClearable = true,
  mdiInput,
  styles,
  setValue,
  helperText,
  mask,
  setErrors,
  clearErrors,
  watch,
  labelRight,
  maskOptions,
  skipCapitalize,
  skipCapitalizePlaceholder,
  hideEyeIcon,
  inputStyles,
  registerProps,
  inputGroupProps,
  inputProps,
  suffixContent,
  prefixContent,
  view,
  isDetail,
  value,
  rows,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const capitalizeFirstLetter = (string) => {
    if (string?.length <= 3) return string;
    return (
      string?.charAt(0)?.toUpperCase() + String(string?.slice(1))?.toLowerCase()
    );
  };
  if (label && !skipCapitalize) {
    label = capitalizeFirstLetter(label) ?? "";
  }
  if (placeholder && !skipCapitalizePlaceholder) {
    placeholder = capitalizeFirstLetter(placeholder) ?? "";
  }

  const checkPasswordType =
    type === "password" ? (showPassword ? "text" : "password") : type;

  useEffect(() => {
    if (inputType === INPUT_TYPE.NIK || inputType === INPUT_TYPE.NO_KK) {
      if (watch(name) && watch(name)?.length !== 16) {
        setErrors(name, { message: `Masukkan NIK/NO KK 16 digit.` });
      }
    }

    if (inputType === INPUT_TYPE.KODEPOS) {
      if (watch(name) && watch(name)?.length !== 5) {
        setErrors(name, { message: `Masukkan Kode Pos 5 digit.` });
      }
    }
    if (inputType === INPUT_TYPE.NIP) {
      if (watch(name) && watch(name)?.length !== 18) {
        setErrors(name, { message: `Masukkan NIP/ID Non ASN 18 digit.` });
      }
    }
    return () => clearErrors(name);
  }, [watch(name, null)]);

  return (
    <div>
      {mdiInput ? (
        <MDIInput
          id={name}
          label={label}
          placeholder={label}
          {...register(name, { required: required, ...registerProps })}
        />
      ) : (
        <FormGroup className={mb} style={inputStyles}>
          {inputType === INPUT_TYPE.CHECKBOX
            ? null
            : labelRight
            ? label && (
                <div className="d-flex justify-content-between align-items-center">
                  <FormLabel
                    htmlFor={name}
                    className=" d-flex text-dark text-[16px]"
                  >
                    {label} {boldLabel && <b>{boldLabel}</b>}{" "}
                    {/* {required && <p className="text-danger mb-0">*</p>} */}
                  </FormLabel>

                  {labelRight}
                </div>
              )
            : label && (
                <FormLabel
                  htmlFor={name}
                  className="text-[16px] d-flex text-dark"
                >
                  {label} {boldLabel && <b>{boldLabel}</b>}{" "}
                  {/* {required && <p className="text-danger mb-0">*</p>} */}
                </FormLabel>
              )}
          {/**
           * Jika kamu mencari dimana input fallbacknya,
           * yaitu input biasa yang tidak dimodify
           * ada dibawah ini no 2
           */}
          {inputType === "ext_input" ? (
            <Controller
              name={name}
              control={control}
              rules={{
                required: required,
                maxLength: maxLength,
                minLength: minLength,
              }}
              render={({ field: { onChange, value } }) => (
                <ExtInput
                  suffixContent={suffixContent}
                  prefixContent={prefixContent}
                  inputGroupProps={inputGroupProps}
                  inputProps={inputProps}
                  placeholder={placeholder}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  hidden={hidden}
                  isInvalid={errors[name]}
                  value={value}
                  onChange={(ev) => onChange(ev.target.value)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.INPUT ? (
            <InputGroup>
              <FormControl
                id={name}
                type={checkPasswordType}
                placeholder={placeholder}
                defaultValue={defaultValue}
                disabled={disabled}
                {...register(name, {
                  required: required,
                  maxLength: maxLength,
                  minLength: minLength,
                  setValueAs: setValueAs,
                  ...registerProps,
                })}
                as={as}
                hidden={hidden}
                isInvalid={errors[name]}
                className="h-full"
              />
              {type === "password" && !hideEyeIcon && (
                <div
                  // type="button"
                  className="btn bg-white border-[#E7EAF0] text-black"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="bi bi-eye-fill" />
                  ) : (
                    <i className="bi bi-eye-slash-fill" />
                  )}
                </div>
              )}
            </InputGroup>
          ) : inputType === INPUT_TYPE.TEXTAREA ? (
            <textarea
              className={`form-control${errors[name] ? " is-invalid" : ""}`}
              type={type}
              style={{ height: height }}
              placeholder={placeholder}
              {...register(name, {
                required: required,
                setValueAs: setValueAs,
                ...registerProps,
              })}
              isInvalid={errors[name]}
              rows={rows}
              disabled={disabled}
            ></textarea>
          ) : inputType === INPUT_TYPE.PRICE ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              defaultValue={defaultValue ?? ""}
              render={({ field: { onChange, value } }) => (
                <PriceInput
                  placeholder={placeholder}
                  value={value}
                  align={align}
                  thousandsSeparatorSymbol=","
                  disabled={disabled}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.SELECT ? (
            <>
              <Controller
                name={name}
                control={control}
                rules={{ required: required === undefined ? true : required }}
                defaultValue={defaultValue ?? null}
                key={key}
                render={({ field: { onChange, value}}) => (
                  <Select
                    id={name}
                    maxMenuHeight={height}
                    isMulti={isMulti}
                    isClearable={true}
                    isDisabled={disabled}
                    options={options}
                    value={value}
                    placeholder={placeholder ?? "Pilih..."}
                    loadingMessage={() => "memuat data..."}
                    noOptionsMessage={() => "data tidak ditemukan"}
                    onChange={(val) => onChange(val)}
                    styles={{   
                      ...styles,
                      ...REACT_SELECT_CUSTOM_STYLES_FN({
                        isMulti: isMulti,
                        control: ( errors?.[name] ? {
                          border: '1px solid red !important'
                        } : {})
                      }),
                    }}
                  />
                )}
              />
            </>
          ) : inputType === INPUT_TYPE.SELECT_CREATABLE ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              defaultValue={null}
              key={key}
              render={({ field: { onChange, value } }) => (
                <SelectCreatable
                  maxMenuHeight={height}
                  isMulti={isMulti}
                  creatable={creatable}
                  isDisabled={disabled}
                  value={value}
                  options={options}
                  placeholder={placeholder}
                  loadingMessage={() => "memuat data..."}
                  noOptionsMessage={() => "data tidak ditemukan"}
                  onChange={(val) => onChange(val)}
                  styles={{
                    ...styles,
                    ...REACT_SELECT_CUSTOM_STYLES,
                  }}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.SELECT_SYNC ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              defaultValue={null}
              render={({ field: { onChange, value}}) => (
                <AsyncSelect
                  value={value}
                  cacheOptions
                  defaultOptions
                  isMulti={isMulti}
                  loadingMessage={() => "memuat data..."}
                  noOptionsMessage={() => "data tidak ditemukan"}
                  loadOptions={options}
                  isClearable={isClearable}
                  isDisabled={disabled}
                  placeholder={placeholder}
                  onChange={(val) => onChange(val)}
                  styles={{
                    ...styles,
                    ...REACT_SELECT_CUSTOM_STYLES_FN({ isMulti }),
                  }}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.TEXT_EDITOR ? (
            <TextEditor
              name={name}
              control={control}
              required={required}
              setValue={setValue}
              defaultValue={defaultValue}
            />
          ) : inputType === INPUT_TYPE.PHONE ? (
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue ?? ""}
              {...register(name, { required: required, ...registerProps })}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  placeholder={placeholder}
                  value={value}
                  disabled={disabled}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.DATE ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              defaultValue={defaultValue ?? ""}
              {...(register && {
                ...register(name, { required: required, ...registerProps }),
              })}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  {...rest}
                  placeholder={placeholder}
                  disabled={disabled}
                  value={value}
                  devaultDate={defaultValue}
                  options={options}
                  onChange={(val, elem) => onChange(elem)}
                  clearable={isClearable}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.MASK ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => (
                <MaskInput
                  placeholder={placeholder}
                  value={value}
                  disabled={disabled}
                  id={name}
                  mask={mask}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.NIK ||
            inputType === INPUT_TYPE.NIP ||
            inputType === INPUT_TYPE.NO_KK ||
            inputType === INPUT_TYPE.KODEPOS ? (
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue ?? ""}
              {...register(name, { required: required, ...registerProps })}
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  placeholder={placeholder}
                  value={value}
                  disabled={disabled}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                  maxLength={
                    inputType === INPUT_TYPE.NIK
                      ? 16
                      : inputType === INPUT_TYPE.NIP
                      ? 18
                      : 6
                  }
                />
              )}
            />
          ) : inputType === INPUT_TYPE.SWITCH ? (
            <Controller
              name={name}
              control={control}
              defaultValue={false}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => {
                const { withData, data } = rest;

                let isChecked;
                if (withData) {
                  if (
                    value != null &&
                    typeof value == "object" &&
                    value.selected != null
                  ) {
                    isChecked = value.selected;
                  } else {
                    isChecked = false;
                  }
                } else {
                  isChecked = value;
                }
                return (
                  <FormCheck
                    type="switch"
                    checked={isChecked}
                    className="form-control-sm"
                    onChange={(e) => {
                      var newVal;
                      if (
                        withData &&
                        value != null &&
                        typeof value == "object" &&
                        value.selected != null
                      ) {
                        newVal = {
                          selected: !value.selected,
                          data: data,
                        };
                      } else if (withData) {
                        newVal = {
                          selected: true,
                          data: data,
                        };
                      } else {
                        newVal = !value;
                      }
                      onChange(newVal);
                    }}
                  />
                );
              }}
            />
          ) : inputType === INPUT_TYPE.ASYNC_SELECT_CREATABLE ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              defaultValue={null}
              key={key}
              render={({ field: { onChange, value } }) => (
                <AsyncSelectCreatable
                  maxMenuHeight={height}
                  isMulti={isMulti}
                  creatable={creatable}
                  isDisabled={disabled}
                  value={value}
                  cacheOptions
                  defaultOptions
                  formatCreateLabel={(userInput) => `Tambahkan ${userInput}`}
                  loadOptions={options}
                  placeholder={placeholder}
                  loadingMessage={() => "memuat data..."}
                  noOptionsMessage={() => "data tidak ditemukan"}
                  onChange={(val) => onChange(val)}
                  styles={{
                    ...styles,
                    ...REACT_SELECT_CUSTOM_STYLES,
                  }}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.CHECKBOX ? (
            <Controller
              name={name}
              control={control}
              defaultValue={false}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => (
                <FormCheck
                  type="checkbox"
                  id={name}
                  label={label}
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.RADIO ? (
            <Controller
              name={name}
              control={control}
              defaultValue={false}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => (
                <FormCheck
                  type="radio"
                  id={name}
                  label={label}
                  checked={value}
                  disabled={disabled}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.PREFIX_SUFFIX ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => (
                <PrefixSuffixInput
                  value={value}
                  disabled={disabled}
                  maskOptions={maskOptions}
                  placeholder={placeholder}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.UPLOAD_IMAGES_GRID ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => (
                <MultipleImages
                  files={value}
                  onupdatefiles={onChange}
                  {...rest}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.UPLOAD_DOC ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => (
                <UploadDoc files={value} onupdatefiles={onChange} {...rest} />
              )}
            />
          ) : inputType === INPUT_TYPE.UPLOAD_PDF ? (
            <UploadPdf
              control={control}
              name={name}
              label={label}
              value={value}
              required={required}
              isDetail={isDetail}
              view={view}
              {...rest}
            />
          ) : inputType === INPUT_TYPE.TIME ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  onChange={onChange}
                  value={value}
                  minTime={rest?.minTime ?? null}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.QUILL ? (
            <Controller
              name={name}
              control={control}
              rules={{ required: required }}
              render={({ field: { onChange, value } }) => (
                <ReactQuill theme="snow" value={value} onChange={onChange} />
              )}
            />
          ) : inputType === INPUT_TYPE.REKENING ? (
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue ?? ""}
              {...register(name, { required: required, ...registerProps })}
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  required={required}
                  // {...register(name, { required: required })}
                  placeholder={placeholder}
                  value={value}
                  disabled={disabled}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                />
              )}
            />
          ) : inputType === INPUT_TYPE.NUMBER ? (
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue ?? ""}
              {...register(name, { required: required, ...registerProps })}
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  placeholder={placeholder}
                  value={value}
                  disabled={disabled}
                  defaultValue={defaultValue}
                  onChange={(val) => onChange(val)}
                  maxLength={maxLength}
                />
              )}
            />
          ) : null}
          {helperText &&
            !errors[name] &&
            (Array.isArray(helperText) ? (
              helperText?.map((helpText) => (
                <FormText>
                  <i className="bi bi-info mr-1" />
                  {helpText}
                </FormText>
              ))
            ) : (
              <FormText>
                <i className="bi bi-info mr-1" />
                {helperText}
              </FormText>
            ))}
          {errors[name] && (
            <Form.Control.Feedback
              className="d-block"
              type="invalid"
              style={{ fontSize: ".85rem" }}
            >
              {errors[name]?.message ? errors[name]?.message : errorMessage}
            </Form.Control.Feedback>
          )}
        </FormGroup>
      )}
    </div>
  );
};

FormInput.defaultProps = {
  type: "text",
  inputType: "input",
  as: "input",
  mb: "mb-4",
  setValueAs: (val) => val,
  setValue: () => {},
  setErrors: () => {},
  clearErrors: () => {},
  watch: () => {},
  hideEyeIcon: false,
  inputStyles: {},
  registerProps: {},
};

FormInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  register: PropTypes.func,
  errors: PropTypes.object,
  control: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  mb: PropTypes.string,
  key: PropTypes.string,
  height: PropTypes.string,
  isMulti: PropTypes.bool,
  creatable: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.func,
  defaultValue: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  hidden: PropTypes.bool,
  setValueAs: PropTypes.func,
  boldLabel: PropTypes.string,
  mdiInput: PropTypes.bool,
  styles: PropTypes.object,
  isClearable: PropTypes.bool,
  setValue: PropTypes.func,
  helperText: PropTypes.string || PropTypes.arrayOf(PropTypes.string),
  mask: PropTypes.array,
  setErrors: PropTypes.func,
  clearErrors: PropTypes.func,
  watch: PropTypes.func,
  labelRight: PropTypes.node,
  maskOptions: PropTypes.object,
  mode: PropTypes.string,
  minDate: PropTypes.string,
  dateFormat: PropTypes.string,
  skipCapitalize: PropTypes.bool,
  skipCapitalizePlaceholder: PropTypes.bool,
  hideEyeIcon: PropTypes.bool,
  inputStyles: PropTypes.string,
  registerProps: PropTypes.object,
};

export default FormInput;
