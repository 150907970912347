import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import FileInput from "./FileInput";

const PDFInput = ({
  name,
  label,
  value,
  control,
  required,
  isDetail,
  view,
  ...rest
}) => {
  const [pdfPreviewPath, setPDFPreviewPath] = useState(null);

  useEffect(() => {
    if (value) {
      // const isValue = !isDetail ? URL.createObjectURL(value?.[0]) : value;
      setPDFPreviewPath(value);
    }
  }, [value]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { value, onChange } }) => (
        <FileInput
          {...rest}
          files={value}
          onChange={onChange}
          acceptedFileTypes={["application/pdf"]}
          helperText="Silakan upload dokumen disini"
        />
      )}
    />
    // <Form.Group controlId={name}>
    //   <div className="d-flex justify-content-between align-items-center">
    //     <Form.Label>{label}</Form.Label>

    //     {value && view && (
    //       <button
    //         type="button"
    //         className="btn btn-link text-primary"
    //         onClick={() => window.open(pdfPreviewPath, "_blank")}
    //       >
    //         Lihat PDF
    //       </button>
    //     )}
    //   </div>
      
    // </Form.Group>
  );
};

export default PDFInput;
