import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { setMenu } from "redux/actions";
import routes from "./routes";
import Preload from './preload';
import { element } from "prop-types";

const filterElementsByPermission = (elements, permissions) => {
  return elements.reduce((acc,el) => {
    // console.log("acc from:, ", acc);
    const element = {...el};
    if (element.permission && !permissions.includes(element.permission)) {
      // acc.push(element);
      return acc;
    }
    if (element.children && element.children.length > 0) {
      element.children = filterElementsByPermission(
        element.children.map((el) => ({...el})),
        permissions
      );
    }
    // return element;
    acc.push(element);
    return acc;
  }, []);
};

const Routers = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => {
    return state?.auth.user;
  });

  const permissions = useMemo(() => {
    return user?.permissions ?? [];
  }, [user?.id]);

  const guardedRoutes = useMemo(() => {
    return filterElementsByPermission(routes, permissions);
  }, [user?.id]);
  document.getPermissions = () => permissions;
  document.getGuardedRoutes = () => guardedRoutes;
  document.getUser = () => user;
  const routing = useRoutes(guardedRoutes);
  useEffect(() => {
    dispatch(setMenu(guardedRoutes));
  }, [user?.id]);

  return <Preload key={user?.id?? "default"}>{routing}</Preload>;
};

export default Routers;