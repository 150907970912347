import { renderToString } from "react-dom/server";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import { isFile } from "utils/helper";
import DocumentUploadIcon from "icons/DocumentUpload";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const FileInput = ({
  files,
  onChange,
  helperText = `Silakan upload foto dalam format JPG atau PNG, gambar otomatis akan
di resize sesuai ukuran`,
  ...rest
}) => {
  // Register Filepond plugins
  registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginPdfPreview
  );

  return (
    <FilePond
      {...rest}
      allowPdfPreview
      pdfPreviewHeight={320}
      files={files}
      maxFileSize="50MB"
      className="file-uploader"
      fileValidateTypeDetectType={(source, type) =>
        new Promise((resolve, reject) => {
          resolve(type);
        })
      }
      pdfComponentExtraParams="toolbar=0&view=fit&page=1"
      onremovefile={(files) => onChange(files)}
      onupdatefiles={(files) => {
        const isFilesFiltered = files?.map((elem) => {
          if (isFile(elem.file)) return elem?.file;
          return elem.source;
        });

        if (isFilesFiltered?.length > 0) {
          onChange(isFilesFiltered);
        }
      }}
      labelIdle={renderToString(
        <div style={{height: "100%"}}>
          <div className="d-flex p-10 justify-content-center align-items-center flex-column gap-3">
            <DocumentUploadIcon />

            <p className="filepond--label-description">{helperText}</p>

            <div className="btn btn-outline-primary mt-4 filepond--label-action">
              <i className="bi bi-arrow-up-square" /> Jelajahi
            </div>
          </div>
        </div>
        
      )}
    />
  );
};

export default FileInput;
