const MenuMaster = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_46_62" fill="white">
        <rect width="8.1" height="9.9" rx="1" />
      </mask>
      <rect
        width="8.1"
        height="9.9"
        rx="1"
        stroke="#6B7BA1"
        stroke-width="2.4"
        mask="url(#path-1-inside-1_46_62)"
      />
      <mask id="path-2-inside-2_46_62" fill="white">
        <rect x="9.90002" y="8.1001" width="8.1" height="9.9" rx="1" />
      </mask>
      <rect
        x="9.90002"
        y="8.1001"
        width="8.1"
        height="9.9"
        rx="1"
        stroke="#6B7BA1"
        stroke-width="2.4"
        mask="url(#path-2-inside-2_46_62)"
      />
      <mask id="path-3-inside-3_46_62" fill="white">
        <rect y="11.7" width="8.1" height="6.3" rx="1" />
      </mask>
      <rect
        y="11.7"
        width="8.1"
        height="6.3"
        rx="1"
        stroke="#6B7BA1"
        stroke-width="2.4"
        mask="url(#path-3-inside-3_46_62)"
      />
      <mask id="path-4-inside-4_46_62" fill="white">
        <rect x="9.90002" width="8.1" height="6.3" rx="1" />
      </mask>
      <rect
        x="9.90002"
        width="8.1"
        height="6.3"
        rx="1"
        stroke="#6B7BA1"
        stroke-width="2.4"
        mask="url(#path-4-inside-4_46_62)"
      />
    </svg>
  );
};

export default MenuMaster;
