import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import DocumentUpload from 'icons/pelayanan/document-upload.svg';

const FileInput = ({ acceptTypes, ...rest }) => {
  // Register Filepond plugins
  registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform
  );

  return (
    <FilePond
      {...rest}
      // maxFileSize="2MB"
      className="file-uploader"
      acceptedFileTypes={acceptTypes}
      labelIdle={`
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img src="${DocumentUpload}" class="mb-3" style="width: 34px; height: 34px">
          </img>
          <div class="mb-7">Silahkan upload file dalam format pdf.</div>
          <div class="btn btn-outline-primary mb-3">
            <span class="bi bi-arrow-up-square mr-3 d-inline-block"></span>Upload File
          </div>
        </div>
      `}
    />
  );
};

FileInput.defaultPops = {
  // acceptTypes: ["image/png", "image/jpeg", "image/webp"]
  acceptTypes: []
}

export default FileInput;
