import VisitorChart from "components/Dashboard/PatientChart";
import StatItem from "components/Dashboard/StatItem";
import PageHeader from "components/PageHeader";
import StatBidan from "icons/dashboard/stats/StatBidan";
import StatDokter from "icons/dashboard/stats/StatDokter";
import StatKamar from "icons/dashboard/stats/StatKamar";
import StatObat from "icons/dashboard/stats/StatObat";
import StatPendapatan from "icons/dashboard/stats/StatPendapatan";
import StatPerawat from "icons/dashboard/stats/StatPerawat";
import { useState } from "react";
import { useSelector } from "react-redux";

const RANGE_OPTIONS = [
  {
    label: "Harian",
    value: "daily",
  },
  {
    label: "Bulanan",
    value: "monthly",
  },
  {
    label: "Tahunan",
    value: "yearly",
  },
];

const statsContent = [
  {
    title: "Tenaga Kerja Medis",
    number: "7",
    icon: <StatDokter />,
  },
  {
    title: "Tenaga Kerja Non Medis",
    number: "15",
    icon: <StatPerawat />,
  },
  {
    title: "Jumlah Pendapatan Bulan Ini",
    number: "2.450.000",
    icon: <StatPendapatan />,
  },
];

const chartStats = [
  {
    title: "Pasien Hari Ini",
    number: 43,
  },
  {
    title: "Total Pasien Bulan Januari",
    number: 120,
  },
  {
    title: "Total Pasien Tahun 2022",
    number: 320,
  },
];

const penyakitStats = [
  {
    title: "Demam Berdarah",
    number: 15,
    color: "#5262FF",
  },
  {
    title: "Hepatitis",
    number: 30,
    color: "#8691FF",
  },
  {
    title: "Malaria",
    number: 7,
    color: "#BAC0FF",
  },
  {
    title: "Tuberkolosis",
    number: 45,
    color: "#2EC7FF",
  },
  {
    title: "Tetanus",
    number: 40,
    color: "#FFB54C",
  },
];

const Dashboard = () => {
  const [chartType, setChartType] = useState("daily");
  const data = useSelector((state) => {
    return state;
  });

  return (
    <>
      <PageHeader title="Dashboard" />
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px]">
        {statsContent.map((item, index) => {
          return (
            <div class="">
              <StatItem
                key={index}
                title={item.title}
                number={item.number}
                icon={item.icon}
              />
            </div>
          );
        })}
      </div>
      <div className="mt-[24px] flex">
        <div className="col-md-8 bg-white rounded-[16px] p-[30px]">
          <div className="text-[16px] font-semibold">
            Grafik Perawatan Pasien
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[8px] mt-5">
            {chartStats.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="p-[16px] border border-[#E7EEFA] rounded-[8px]"
                >
                  <div className="text-[24px] font-bold text-center">
                    {item.number}
                  </div>
                  <div className="text-center text-[#8B95A5] text-xs mt-[6px]">
                    {item.title}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-4 flex justify-end">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <select
                style={{
                  width: "160px",
                }}
                class="form-select"
                onChange={(e) => {
                  const { value } = e.target;
                  setChartType(value);
                }}
              >
                {RANGE_OPTIONS.map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
              </select>
            </div>
          </div>
          <VisitorChart type={chartType} />
        </div>

        {/* <div className="col-md-4 h-[100%] lg:pl-[20px]"> */}
          <div className="col-md-4 lg:pl-[20px] flex flex-column">

            <div className="bg-white p-[30px] rounded-[16px]" style={{height: "50%"}}>
              <div className="flex items-center justify-between">
                <div className="text-[16px] font-bold">Penjamin</div>
                <div className="text-[12px] text-[#8B95A5]">3800 Jiwa</div>
              </div>
              <div className="mt-[24px]">
                <div className="rounded-full h-[10px] flex items-center">
                  <div
                    className="rounded-l-full bg-[#5EE2CB] h-[10px]"
                    style={{ width: "60%" }}
                  ></div>
                  <div
                    className="rounded-r-full bg-[#BFF3EA] h-[10px]"
                    style={{ width: "40%" }}
                  ></div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[16px] mt-[20px]">
                <div className="flex items-start gap-2">
                  <div className="pt-[6px]">
                    <div className="w-[10px] h-[10px] rounded-full bg-[#5EE2CB]" />
                  </div>
                  <div>
                    <div className="font-bold text-xs">BPJS</div>
                    <div className="text-[10px] text-[#8B95A5]">2500 Jiwa</div>
                  </div>
                </div>
                <div>
                  <div className="flex items-start gap-2">
                    <div className="pt-[6px]">
                      <div className="w-[10px] h-[10px] rounded-full bg-[#BFF3EA]" />
                    </div>
                    <div>
                      <div className="font-bold text-xs">Umum</div>
                      <div className="text-[10px] text-[#8B95A5]">1300 Jiwa</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-[24px]">
                <div className="text-[16px] font-bold">Jenis Kelamin</div>
                <div className="text-[12px] text-[#8B95A5]">3800 Jiwa</div>
              </div>
              <div className="mt-[24px]">
                <div className="rounded-fullh-[10px] flex items-center">
                  <div
                    className="rounded-l-full bg-[#6DD8FF] h-[10px]"
                    style={{ width: "70%" }}
                  ></div>
                  <div
                    className="rounded-r-full bg-[#B69DFF] h-[10px]"
                    style={{ width: "30%" }}
                  ></div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-[16px] mt-[20px]">
                <div className="flex items-start gap-2">
                  <div className="pt-[6px]">
                    <div className="w-[10px] h-[10px] rounded-full bg-[#6DD8FF]" />
                  </div>
                  <div>
                    <div className="font-bold text-xs">Laki-laki</div>
                    <div className="text-[10px] text-[#8B95A5]">2680 Jiwa</div>
                  </div>
                </div>
                <div>
                  <div className="flex items-start gap-2">
                    <div className="pt-[6px]">
                      <div className="w-[10px] h-[10px] rounded-full bg-[#B69DFF]" />
                    </div>
                    <div>
                      <div className="font-bold text-xs">Perempuan</div>
                      <div className="text-[10px] text-[#8B95A5]">1120 Jiwa</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-[30px] rounded-[16px] mt-[24px]" style={{height: "50%"}}>
            <div className="flex items-center justify-between">
              <div className="text-[16px] font-bold">Penyakit</div>
              <div className="text-[12px] text-[#8B95A5]">Total 370 Pasien</div>
            </div>
            <div className="mt-[20px]">
              <div className="rounded-full h-[10px] flex items-center overflow-clip">
                {penyakitStats.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        width: `${
                          (item.number /
                            penyakitStats.reduce(
                              (acc, cur) => acc + cur.number,
                              0
                            )) *
                          100
                        }%`,
                        height: "10px",
                        backgroundColor: item.color,
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mt-[20px] grid grid-cols-2 gap-4">
              <div>
                {penyakitStats.slice(0, 4).map((item, idx) => {
                  return (
                    <div className="flex items-start gap-2 mb-3">
                      <div className="pt-[6px]">
                        <div
                          className="w-[10px] h-[10px] rounded-full"
                          style={{
                            backgroundColor: item.color,
                          }}
                        />
                      </div>
                      <div>
                        <div className="font-bold whitespace-nowrap text-xs">
                          {item.title}
                        </div>

                        <div className="text-[10px] text-[#8B95A5]">
                          {item.number} Pasien
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="pl-6">
                {penyakitStats.slice(4, 999).map((item, idx) => {
                  return (
                    <div className="flex items-start gap-2  mb-3">
                      <div className="pt-[6px]">
                        <div
                          className="w-[10px] h-[10px] rounded-full"
                          style={{
                            backgroundColor: item.color,
                          }}
                        />
                      </div>
                      <div>
                        <div className="font-bold text-xs">{item.title}</div>
                        <div className="text-[10px] text-[#8B95A5]">
                          {item.number} Pasien
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            </div>

          </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Dashboard;
