import logo from "./logo.svg";
import "./App.css";
import { Suspense, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./routes";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Router>
      <Suspense fallback="loading">
        <Toaster />
        <Routers />
      </Suspense>
    </Router>
  );
}

export default App;
