const StatPendapatan = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill="#FF579A" />
      <g clip-path="url(#clip0_880_13669)">
        <path
          d="M34.1875 18C29.8063 18 26.375 20.0594 26.375 22.6875V25.3823C25.8708 25.3229 25.349 25.2917 24.8125 25.2917C20.4313 25.2917 17 27.351 17 29.9792V38.3125C17 40.9406 20.4313 43 24.8125 43C28.3708 43 31.3031 41.6417 32.2781 39.7229C32.9 39.8135 33.5354 39.875 34.1875 39.875C38.5688 39.875 42 37.8156 42 35.1875V22.6875C42 20.0594 38.5688 18 34.1875 18ZM34.1875 20.0833C37.4667 20.0833 39.9167 21.4583 39.9167 22.6875C39.9167 23.9167 37.4667 25.2917 34.1875 25.2917C30.9083 25.2917 28.4583 23.9167 28.4583 22.6875C28.4583 21.4583 30.9083 20.0833 34.1875 20.0833ZM24.8125 27.375C28.0917 27.375 30.5417 28.75 30.5417 29.9792C30.5417 31.2083 28.0917 32.5833 24.8125 32.5833C21.5333 32.5833 19.0833 31.2083 19.0833 29.9792C19.0833 28.75 21.5333 27.375 24.8125 27.375ZM19.0833 33.1896C20.5 34.101 22.524 34.6667 24.8125 34.6667C27.101 34.6667 29.125 34.101 30.5417 33.1896V34.1458C30.5417 35.375 28.0917 36.75 24.8125 36.75C21.5333 36.75 19.0833 35.375 19.0833 34.1458V33.1896ZM24.8125 40.9167C21.5333 40.9167 19.0833 39.5417 19.0833 38.3125V37.3563C20.5 38.2677 22.524 38.8333 24.8125 38.8333C27.101 38.8333 29.125 38.2677 30.5417 37.3563V38.3125C30.5417 39.5417 28.0917 40.9167 24.8125 40.9167ZM34.1875 37.7917C33.6521 37.7917 33.1333 37.7427 32.625 37.6708V35.6083C33.1375 35.6688 33.6563 35.7083 34.1875 35.7083C36.476 35.7083 38.5 35.1427 39.9167 34.2313V35.1875C39.9167 36.4167 37.4667 37.7917 34.1875 37.7917ZM34.1875 33.625C33.6521 33.625 33.1333 33.576 32.625 33.5042V31.4417C33.1375 31.5021 33.6563 31.5417 34.1875 31.5417C36.476 31.5417 38.5 30.976 39.9167 30.0646V31.0208C39.9167 32.25 37.4667 33.625 34.1875 33.625ZM34.1875 29.4583C33.6229 29.4583 33.0771 29.4052 32.5438 29.326C32.3375 28.4021 31.701 27.5719 30.751 26.9083C31.7844 27.2052 32.9448 27.376 34.1875 27.376C36.476 27.376 38.5 26.8104 39.9167 25.899V26.8552C39.9167 28.0844 37.4667 29.4594 34.1875 29.4594V29.4583Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_880_13669">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(17 18)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StatPendapatan;
