import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { isUserAuthenticated } from "utils/auth";
import LockImage from "assets/forgot-password/lock.png";
import { Form } from "react-bootstrap";
import FormInput from "components/Input";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { request } from "utils/request";
import { toast } from "react-hot-toast";

const ResetPassword = () => {
  const { search: searchParams } = useLocation();
  const isLoggin = isUserAuthenticated();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const watchToken = new URLSearchParams(searchParams).get("token");
  const watchEmail = new URLSearchParams(searchParams).get("email");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    watch,
  } = useForm();

  const watchNewPassword = watch("password");
  const watchConfirmNewPassword = watch("password_confirmation");
  useEffect(() => {
    if (
      watchConfirmNewPassword &&
      watchConfirmNewPassword !== watchNewPassword
    ) {
      setError("password_confirmation", {
        message: "Konfirmasi password tidak cocok !",
      });
    }
    return () => clearErrors("password_confirmation");
  }, [watchNewPassword, watchConfirmNewPassword]);

  const submitRequest = (values) => {
    setLoading(true);
    request
      .post("/api/password/reset", {
        ...values,
        email: watchEmail,
        token: watchToken,
      })
      .then((res) => {
        toast.success(
          res.data.message || res.data.msg || "Password berhasil direset"
        );

        setTimeout(() => {
          navigate("/login");
        }, 1000);
      })
      .finally(() => setLoading(false));
  };

  // if (!watchToken || !watchEmail) {
  // return <Navigate to="/" />;
  // }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="max-w-[600px] md:shadow-[8px_14px_38px_rgba(0,_0,_0,_0.1)] rounded-[14px] p-3 md:p-[106px_85px] flex items-center justify-center w-full md:w-[70%] lg:w-[50%] h-[600px]">
        <div>
          <img src={LockImage} alt="lock" className="mx-auto" />
          <h1 className="text-[28px] font-bold mt-5 mx-auto text-center">
            Ubah Password
          </h1>
          <Form onSubmit={handleSubmit(submitRequest)}>
            <div className="rounded-input-container mt-10">
              <FormInput
                skipCapitalize
                label="Password Baru"
                type="password"
                name="password"
                required={true}
                errors={errors}
                register={register}
                placeholder="Masukkan password baru"
                errorMessage="Masukkan password baru"
                inputStyles={{
                  width: "100%",
                  maxWidth: "431px",
                  borderRadius: "7px",
                }}
              />

              <FormInput
                skipCapitalize
                label="Konfirmasi Password Baru"
                type="password"
                name="password_confirmation"
                required={true}
                errors={errors}
                register={register}
                placeholder="Masukkan konfirmasi password baru"
                errorMessage="Masukkan konfirmasi password baru"
                inputStyles={{
                  width: "100%",
                  maxWidth: "431px",
                  borderRadius: "7px",
                }}
              />
            </div>
            <div className="w-full mt-[30px]">
              <button
                className="text-[16px] font-medium text-white bg-[#00BFA6] p-4 rounded-[7px] w-full cursor-pointer"
                type="submit"
                disabled={!isValid || loading}
              >
                {loading ? "Loading..." : "Ubah Password"}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
