import { REMOVE_USER_DATA, SET_USER_DATA } from "./type";

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  payload: userData,
});

export const removeUserData = () => ({
  type: REMOVE_USER_DATA,
});
