import Sidebar from "components/Sidebar";
import Topbar from "components/Topbar";
import { Link } from "react-router-dom";
import SimpusLogo from "assets/logo/simpus.png";

const AppLayout = ({
  children,
  hideSidebar = false,
  topRightComponent = null,
}) => {
  return (
    <div className="relative">
      <Topbar topRightComponent={topRightComponent} />
      <div className="d-flex flex-column flex-lg-row h-lg-full">
        {!hideSidebar && <Sidebar />}

        <div
          id="content"
          className="flex-lg-1 overflow-y-lg-auto bg-surface-secondary pt-0"
        >
          <main className="container-fluid">
            <div className="p-lg-4 mt-16 p-0 mt-0">{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
