const MenuKeuangan = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_52_259)">
        <g clip-path="url(#clip1_52_259)">
          <path
            d="M14.25 0H3.75C1.68225 0 0 1.68225 0 3.75V14.25C0 16.3177 1.68225 18 3.75 18H14.25C16.3177 18 18 16.3177 18 14.25V3.75C18 1.68225 16.3177 0 14.25 0ZM16.5 14.25C16.5 15.4905 15.4905 16.5 14.25 16.5H3.75C2.5095 16.5 1.5 15.4905 1.5 14.25V3.75C1.5 2.5095 2.5095 1.5 3.75 1.5H14.25C15.4905 1.5 16.5 2.5095 16.5 3.75V14.25ZM12 10.5C12 11.7405 10.9905 12.75 9.75 12.75V13.5C9.75 13.9148 9.41475 14.25 9 14.25C8.58525 14.25 8.25 13.9148 8.25 13.5V12.75H8.049C7.24875 12.75 6.50175 12.3195 6.1005 11.6258C5.89275 11.2665 6.01575 10.8083 6.3735 10.6012C6.73275 10.392 7.191 10.5165 7.398 10.8742C7.53225 11.1067 7.78125 11.25 8.04825 11.25H9.74925C10.1632 11.25 10.4992 10.914 10.4992 10.5C10.4992 10.2165 10.296 9.9765 10.0162 9.93L7.7355 9.54975C6.729 9.3825 5.99925 8.52 5.99925 7.5C5.99925 6.2595 7.00875 5.25 8.24925 5.25V4.5C8.24925 4.086 8.5845 3.75 8.99925 3.75C9.414 3.75 9.74925 4.086 9.74925 4.5V5.25H9.95025C10.7505 5.25 11.4975 5.68125 11.8988 6.375C12.1065 6.7335 11.9835 7.19175 11.6258 7.3995C11.2657 7.60725 10.8075 7.48425 10.6012 7.12575C10.467 6.894 10.218 6.75075 9.951 6.75075H8.25C7.836 6.75075 7.5 7.0875 7.5 7.50075C7.5 7.78425 7.70325 8.02425 7.983 8.07075L10.2638 8.451C11.2703 8.61825 12 9.48075 12 10.5008V10.5Z"
            fill="#6B7B93"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_52_259">
          <rect width="18" height="18" fill="white" />
        </clipPath>
        <clipPath id="clip1_52_259">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuKeuangan;
