import PropTypes from "prop-types";
import {useMemo} from 'react';
import { FormControl } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "Rp ",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 5, // how many digits allowed after the decimal
  integerLimit: 20, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const PriceInput = ({ maskOptions, align, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  // var align = useMemo(() => {
  //   switch (align) {
  //     case "right":
  //       return "end";
  //     case "left":
  //       return "start";
  //     case "center":
  //       return "center";
  //   }
  // },[align]);

  return (
    <FormControl
      mask={currencyMask}
      {...inputProps}
      as={MaskedInput}
      style={{ height: "48px" , textAlign: align}}
    />
  );
};
PriceInput.defaultProps = {
  align: "left",
  inputMode: "numeric",
  maskOptions: {},
};

PriceInput.propTypes = {
  // align: PropTypes.enum,
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.bool,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.bool,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowLeadingZeroes: PropTypes.bool,
    integerLimit: PropTypes.number,
  }),
};

export default PriceInput;
