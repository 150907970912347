const DocumentUploadIcon = () => (
  <svg
    width={30}
    height={30}
    color="#16192c"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 28.3335V18.3335L11.6666 21.6668"
      stroke="#7E8299"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 18.3335L18.3333 21.6668"
      stroke="#7E8299"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.6667 16.6668V25.0002C36.6667 33.3335 33.3334 36.6668 25 36.6668H15C6.66671 36.6668 3.33337 33.3335 3.33337 25.0002V15.0002C3.33337 6.66683 6.66671 3.3335 15 3.3335H23.3334"
      stroke="#7E8299"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.6667 16.6668H30C25 16.6668 23.3334 15.0002 23.3334 10.0002V3.3335L36.6667 16.6668Z"
      stroke="#7E8299"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentUploadIcon;
