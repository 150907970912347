import AppLayout from "layouts";
import { useDispatch } from "react-redux";
import { isUserAuthenticated } from "utils/auth";
import { useEffect, useState } from "react";
import { request } from "utils/request";
import { setUserData } from "redux/actions";
import { Navigate } from "react-router-dom";
import LoadingGlobal from "components/commons/LoadingGlobal";

const PrivateRoute = ({ children }) => {
  const isLoggin = isUserAuthenticated();

  const dispatch = useDispatch();

  const [loadingGetUserData, setLoadingGetUserData] = useState(false);

  const getUserData = async () => {
    setLoadingGetUserData(true);

    try {
      const responseUser = await request.post("/api/user/me");

      if (responseUser) {
        const role = responseUser?.data?.data?.roles?.[0];
        const userData = {
          ...responseUser?.data?.data,
          permissions: role?.permissions?.map((item) => item.name),
        };
        dispatch(setUserData(userData));
      }
    } finally {
      setLoadingGetUserData(false);
    }
  };

  useEffect(() => {
    if (isLoggin) {
      getUserData();
    }
  }, [isLoggin]);

  // if (isLoggin) return <AppLayout />;
  if (isLoggin) return <AppLayout>{children}</AppLayout>;

  if (loadingGetUserData) return <LoadingGlobal />;

  return <Navigate to="/login" />;
};

export default PrivateRoute;
