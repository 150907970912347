import {useEffect} from 'react';
import {
  InputGroup
} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function ExtInput({
  prefixContent,
  suffixContent,
  inputGroupProps,
  inputProps,
  name,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  disabled,
  hidden,
  isInvalid,

  required,
  value,
  ...props
}) {
  const igProps0 = {...inputGroupProps};
  const iProps0 = {...inputProps};
  var igClass = "ext-input-group";
  if (inputGroupProps.className != null) {
    igClass += " "+igProps0.className;
  }
  igProps0.className = igClass;

  var icClass = "ext-input-control";
  if (inputProps.className != null) {
    icClass += " "+iProps0.className;
  }
  iProps0.className = icClass;
  return (
    <InputGroup {...igProps0}>
      {prefixContent == null? null : prefixContent}
      <input
        {...iProps0}
        id={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        hidden={hidden}
        isInvalid={isInvalid}
        autocomplete="off"
        onChange={onChange}
        value={value}
      />
      {suffixContent == null? null : suffixContent}
    </InputGroup>
  );
}

ExtInput.defaultProps = {
  inputGroupProps: {
    className: null
  },
  inputProps: {
    className: null
  }
}

ExtInput.propTypes = {
  inputGroupProps: PropTypes.object,
  inputProps: PropTypes.object,
}