import { Indonesian } from "flatpickr/dist/l10n/id";
import FlatPickr from "react-flatpickr";
import PropTypes from "prop-types";
import { useRef } from "react";

const DatePicker = ({ customFormatDate,options, ...rest }) => {
  const pickerRef = useRef(null);

  return (
    <div
      style={{
        position: "relative",
        ...rest?.containerStyle,
      }}
    >
      <FlatPickr
        ref={pickerRef}
        {...rest}
        defaultDate={rest?.defaultDate ?? null}
        placeholder={rest.placeholder ?? ""}
        defaultValue={null}
        options={{
          locale: Indonesian,
          ...options,
          altInput: true,
          disableMobile: true,
          dateFormat:  "Y-m-d",
          altFormat: "d-m-Y",
          position: "below center",
        }}
        className={`form-control ${rest?.disabled ? "" : "bg-white"}`}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          top: "25%",
          right: "15px",
        }}
      >
        {!!rest?.value && (
          <button
            style={{
              position: "absolute",
              transform: "translateY(-50%)",
              top: "9px",
              rotate: "45deg",
              width: "20px",
              height: "20px",
              border: "1px solid #e4e6ef",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              right: "36px",
            }}
            type="button"
            onClick={() => pickerRef.current.flatpickr.clear()}
          >
            +
          </button>
        )}
        <i
          className="bi bi-calendar"
          style={{ top: "-50px", width: "20px", height: "20px" }}
          role="button"
          onClick={() => {
            // toggle flatpickr
            pickerRef.current.flatpickr.open();
          }}
        />
      </div>
    </div>
  );
};

DatePicker.defaultProps = {
  options: {
    locale: Indonesian,
  },
};

DatePicker.propTypes = {
  options: PropTypes.object,
};

export default DatePicker;
