import loginBg from "assets/login/login-bg.png";
import logo from "assets/logo/simpus.png";
import Input from "components/Input";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { request } from "utils/request";
import { isUserAuthenticated, setIsRefresh, setToken } from "utils/auth";
import { useDispatch } from "react-redux";
import { setUserData } from "redux/actions";

const Login = () => {
  const isLoggin = isUserAuthenticated();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const handleLogin = (values) => {
    setLoading(true);

    request
      .post("/api/login", {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        if (values.keep_login) {
          setIsRefresh(true);
        }
        setToken(res.data.data.token);
        const responseUser = res;
        if (responseUser) {
          const role = responseUser?.data?.data?.user.roles?.[0];
          const userData = {
            ...responseUser?.data?.data.user,
            permissions: role?.permissions?.map((item) => item.name),
            token: responseUser?.data?.data?.token,
          };
          dispatch(setUserData(userData));
        }
      })
      .finally(() => setLoading(false));
  };

  if (isLoggin) {
    return <Navigate to="/" />;
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center md:px-5">
      <div className="h-[600px] max-w-[800px] flex rounded-[14px] mx-auto sm:shadow-[8px_14px_38px_rgba(0,_0,_0,_0.1)] overflow-clip">
        <div className="hidden md:block w-max">
          <img src={loginBg} alt="login" className="w-full h-full" />
        </div>
        <div className="flex items-center justify-center w-[450px]">
          <div className="flex items-center justify-center flex-col w-[90%] sm:m-auto p-4">
            <div>
              <img src={logo} alt="simpus" />
            </div>
            <div>
              <h2 className="text-[16px] text-[#7E8299] text-center mt-[30px]">
                Silakan masuk menggunakan Akun Sistem Informasi Puskesmas
              </h2>
            </div>
            <div className="w-full mt-[40px]">
              <Form autoComplete="off" onSubmit={handleSubmit(handleLogin)}>
                <Input
                  type="text"
                  name="email"
                  required={true}
                  errors={errors}
                  label="Email"
                  register={register}
                  placeholder="Masukkan email"
                  errorMessage="Masukkan email"
                  inputStyles={{
                    borderRadius: "5-%",
                  }}
                />

                <Input
                  type="password"
                  name="password"
                  required={true}
                  errors={errors}
                  label="Password"
                  register={register}
                  placeholder="Masukkan password"
                  errorMessage="Masukkan password"
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "24px",
                  }}
                >
                  <Input
                    inputType="checkbox"
                    name="keep_login"
                    errors={errors}
                    control={control}
                    label="Ingatkan Saya"
                  />

                  <Link
                    className="text-[#00BFA6] hover:text-[#007B6B] text-base"
                    to="/forgot-password"
                  >
                    Lupa password?
                  </Link>
                </div>
                <div className="w-full mt-[56px]">
                  <button
                    className={`text-[16px] font-medium h-[50px] text-white bg-[#00BFA6] p-[13px] rounded-[6px] w-full transition-all duration-150 ${
                      loading ? " " : " hover:bg-[#007B6B]"
                    }`}
                    type="submit"
                    disabled={!isValid || loading}
                  >
                    {loading ? "Loading..." : "Masuk"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
