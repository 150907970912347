const StatKamar = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill="#00D4FF" />
      <g clip-path="url(#clip0_880_13685)">
        <path
          d="M37.7917 19.084H23.2083C20.3365 19.084 18 21.4204 18 24.2923V38.8757C18 39.4517 18.4667 39.9173 19.0417 39.9173C19.6167 39.9173 20.0833 39.4517 20.0833 38.8757V36.7923H40.9167V38.8757C40.9167 39.4517 41.3823 39.9173 41.9583 39.9173C42.5344 39.9173 43 39.4517 43 38.8757V24.2923C43 21.4204 40.6635 19.084 37.7917 19.084ZM23.2083 21.1673H37.7917C39.5146 21.1673 40.9167 22.5694 40.9167 24.2923V30.5423H38.8333C38.8333 28.2444 36.9646 26.3757 34.6667 26.3757H33.625C32.3813 26.3757 31.2646 26.9236 30.5 27.7892C29.7354 26.9225 28.6188 26.3757 27.375 26.3757H26.3333C24.0354 26.3757 22.1667 28.2444 22.1667 30.5423H20.0833V24.2923C20.0833 22.5694 21.4854 21.1673 23.2083 21.1673ZM31.5417 30.5423C31.5417 29.3934 32.476 28.459 33.625 28.459H34.6667C35.8156 28.459 36.75 29.3934 36.75 30.5423H31.5417ZM24.25 30.5423C24.25 29.3934 25.1844 28.459 26.3333 28.459H27.375C28.524 28.459 29.4583 29.3934 29.4583 30.5423H24.25ZM20.0833 34.709V32.6257H40.9167V34.709H20.0833Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_880_13685">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(18 17)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StatKamar;
