import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import SimpusLogo from "assets/logo/simpus.png";

const Topbar = ({ topRightComponent = null }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <nav
      id="topbar"
      className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none bg-white border-bottom px-0 py-3"
      style={{
        borderBottom: "1px solid #e5e5e5",
      }}
    >
      <div className="container-fluid">
        <Link to="/" className="d-flex align-items-center gap-5">
          <img alt="simpus" src={SimpusLogo} className="logo-left" />
        </Link>
        {topRightComponent ? (
          topRightComponent
        ) : (
          <div className="navbar-user d-none d-sm-block ms-auto">
            <div className="hstack gap-3 ms-4">
              <div className="dropdown">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="d-flex align-items-center"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <div>
                    <div className="avatar avatar-sm bg-warning rounded-circle text-white">
                      <Avatar
                        src={user?.avatar}
                        alt={user?.email}
                        name={user?.email}
                        size="40px"
                        color="#00BFA6"
                        className="avatar avatar-sm rounded-circle"
                      />
                    </div>
                  </div>
                  <div className="d-none d-sm-block ms-3">
                    <span className="h6">{user?.email}</span>
                  </div>
                  <div className="d-none d-md-block ms-md-2">
                    <i className="bi bi-chevron-down text-muted text-xs"></i>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end">
                  <div className="dropdown-header">
                    <span className="d-block text-sm text-muted mb-1">
                      Signed in as
                    </span>
                    <span className="d-block text-heading font-semibold">
                      {user?.email}
                    </span>
                  </div>
                  <div className="dropdown-divider"></div>
                  {/* <Link className="dropdown-item" to="/poli-umum/dashboard">
                    <i className="bi bi-hospital me-3"></i>Poli Umum
                  </Link>
                  <Link className="dropdown-item" to="/poli-gigi/dashboard">
                    <i className="bi bi-hospital me-3"></i>Poli Gigi
                  </Link>
                  <Link className="dropdown-item" to="/apotek/dashboard">
                    <i className="bi bi-capsule me-3"></i>Apotek
                  </Link>
                  <Link className="dropdown-item" to="/keuangan/dashboard">
                    <i className="bi bi-cash-coin me-3"></i>Administrasi
                    Keuangan
                  </Link> */}
                  <Link className="dropdown-item" to="/ubah-password/dashboard">
                    <i className="bi bi-pencil me-3"></i>Ubah Password
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="/logout">
                    <i className="bi bi-box-arrow-left me-3"></i>Keluar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Topbar;
