import { useDispatch } from "react-redux";
import { isUserAuthenticated } from "utils/auth";
import { useEffect, useState } from "react";
import { request } from "utils/request";
import { setUserData } from "redux/actions";
import { useNavigate } from "react-router-dom";
import LoadingGlobal from "components/commons/LoadingGlobal";

const replace = { replace: true };

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const isLogged = isUserAuthenticated();
  const dispatch = useDispatch();

  const [loadingGetUserData, setLoadingGetUserData] = useState(true);

  const getUserData = () => {
    setLoadingGetUserData(true);
    request
      .post("/api/user/me")
      .then(
        (responseUser) => {
          if (responseUser) {
            const role = responseUser?.data?.data?.roles?.[0];
            const userData = {
              ...responseUser?.data?.data,
              permissions: role?.permissions?.map((item) => item.name),
            };
            dispatch(setUserData(userData));
          }
        },
        () => {
          navigate("/login");
        }
      )
      .finally(() => {
        setLoadingGetUserData(false);
      });
  };

  useEffect(() => {
    if (isLogged) {
      getUserData();
    }
    if (isLogged == false && loadingGetUserData) {
      setLoadingGetUserData(false);
      // navigate("/login", replace);
    }
  }, [isLogged]);

  if (loadingGetUserData) return <LoadingGlobal />;
  return children;
};

export default PrivateRoute;
