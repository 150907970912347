export const replaceAll = (text, replaceFrom, replaceAfter) => {
  const regex = new RegExp(replaceFrom, "g");
  const results = text?.replace(regex, replaceAfter);

  return results;
};

export const isFile = (input) => {
  if ("File" in window && input instanceof File) return true;
  else return false;
};

export const findActiveMenuInRoutes = (match, path, childs, pathname) => {
  // if (match) {
  //   const isHaveChild = childs?.length > 0;

  //   if (isHaveChild) {
  //     const results = childs.some((child) => child.path === pathname);

  //     return results;
  //   }

  //   return path === match.pathname;
  // }

  // return false;
  if (match) {
    const hasChildren = childs?.length > 0;

    if (hasChildren) {
      for (const child of childs) {
        if (
          findActiveMenuInRoutes(match, child.path, child.children, pathname)
        ) {
          return true;
        }
      }

      return false;
    }

    return path === match.pathname;
  }

  return false;
};

export const toRupiah = (angka) => {
  const rupiah = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka || 0);
  return `${rupiah} ,-`;
};

export const toTitleCase = (str) => {
  if (!str) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const capitalizeFirstLetter=(str)=> {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getNumberList = (index, page = 1, perPage = 5) => {
  return index + 1 + (parseInt(page ?? 1) - 1) * parseInt(perPage ?? 5);
};

export function suffixSplicer(value, suffix) {
  if (value != null && typeof value == "string") {
    value.endsWith(suffix);
    const result = value.split(suffix);
    return result[0];
  } else {
    return value;
  }
}